export default () => {
  return {
    connection: null,
    connections: [],
    dataProduct: null,
    dataProductDestinations: [],
    dataProductHistory: [],
    dataProductTestDestinations: [],
    dataProducts: [],
    datasetEvents: {},
    datasets: [],
    destinations: [],
    dismissedDeployAlerts: [],
    lastDataProductId: null,
    profilingNotificationActive: null,
    profilingNotificationLoading: false,
    resources: [],
    shouldShowProfileNotificationModal: false,
    testDestinations: [],
  };
};
