import { uniqueId } from 'lodash-es';
import { syllable } from 'syllable';

import { ERROR, INFO, SUCCESS, WARNING } from '~/components/cx';
import { NotificationsService } from '~/support/services';

import {
  ADD_TOAST_NOTIFICATION,
  CLEAR_NOTIFICATION_CHANNELS,
  REMOVE_CHIRP_ICON,
  REMOVE_CHIRP_NOTIFICATION,
  REMOVE_TOAST_NOTIFICATION,
  SET_CHIRP_ICON,
  SET_CHIRP_NOTIFICATION,
  SET_NOTIFICATION_CHANNELS,
} from './mutation-types';

export const MILLISECONDS_PER_SYLLABLE = 500;

export function calculateNotificationTimeout(message) {
  return Math.max(syllable(message) * MILLISECONDS_PER_SYLLABLE);
}

let chirpTimeout;

export default {
  async applyRuleToAssociatedDatasets(_, { datasetId, payload }) {
    const service = new NotificationsService(useRuntimeConfig().public.notificationsBaseUrl);
    const data = await service.applyRuleToAssociatedDatasets(datasetId, payload);

    return data;
  },

  chirp({ commit }, message) {
    clearTimeout(chirpTimeout);
    commit(REMOVE_CHIRP_ICON);

    commit(SET_CHIRP_NOTIFICATION, message);

    chirpTimeout = setTimeout(() => {
      commit(REMOVE_CHIRP_NOTIFICATION);
    }, calculateNotificationTimeout(message));
  },

  chirpWithIcon({ commit }, { message, icon }) {
    clearTimeout(chirpTimeout);

    commit(SET_CHIRP_NOTIFICATION, message);
    commit(SET_CHIRP_ICON, icon);

    chirpTimeout = setTimeout(() => {
      commit(REMOVE_CHIRP_NOTIFICATION);
      commit(REMOVE_CHIRP_ICON);
    }, calculateNotificationTimeout(message));
  },

  clearChannels({ commit }) {
    commit(CLEAR_NOTIFICATION_CHANNELS);
  },

  async createChannel(_, payload) {
    const service = new NotificationsService(useRuntimeConfig().public.notificationsBaseUrl);
    const data = await service.createChannel(payload);

    return data;
  },

  async createRule(_, payload) {
    const service = new NotificationsService(useRuntimeConfig().public.notificationsBaseUrl);
    const data = await service.createRule(payload);

    return data;
  },

  async deleteChannel(_, id) {
    const service = new NotificationsService(useRuntimeConfig().public.notificationsBaseUrl);
    const data = await service.deleteChannel(id);

    return data;
  },

  async deleteRule(_, id) {
    const service = new NotificationsService(useRuntimeConfig().public.notificationsBaseUrl);
    const data = await service.deleteRule(id);

    return data;
  },

  async fetchAllChannels({ commit }) {
    const service = new NotificationsService(useRuntimeConfig().public.notificationsBaseUrl);

    try {
      const data = await service.fetchChannels();

      commit(SET_NOTIFICATION_CHANNELS, data);

      return data;
    } catch (error) {
      commit(SET_NOTIFICATION_CHANNELS, []);
    }
  },

  async fetchChannels(_, method) {
    const service = new NotificationsService(useRuntimeConfig().public.notificationsBaseUrl);
    const data = await service.fetchChannels(method);

    return data;
  },

  async fetchEmailPreferences() {
    const service = new NotificationsService(useRuntimeConfig().public.notificationsBaseUrl);
    const data = await service.fetchEmailPreferences();

    return data;
  },

  async fetchRule(_, id) {
    const service = new NotificationsService(useRuntimeConfig().public.notificationsBaseUrl);
    const data = await service.fetchRule(id);

    return data;
  },

  async fetchRuleByDataset(_, datasetId) {
    const service = new NotificationsService(useRuntimeConfig().public.notificationsBaseUrl);
    const data = await service.fetchRuleByDataset(datasetId);

    return data;
  },

  async fetchRules(_, { orgId, pageToken, query, sortSettings }) {
    const service = new NotificationsService(useRuntimeConfig().public.notificationsBaseUrl);
    const data = await service.fetchRules({ orgId, pageToken, query, sortSettings });

    return data;
  },

  removeToast({ commit }, id) {
    commit(REMOVE_TOAST_NOTIFICATION, { id });
  },

  showErrorToast({ dispatch }, message) {
    dispatch('showToast', { message, status: ERROR });
  },

  showInfoToast({ dispatch }, message) {
    dispatch('showToast', { message, status: INFO });
  },

  showSuccessToast({ dispatch }, message) {
    dispatch('showToast', { message, status: SUCCESS });
  },

  showToast({ commit }, toast) {
    toast = { id: uniqueId('toast'), ...toast };
    const parsedToastTimeout = calculateNotificationTimeout(toast.message);

    commit(ADD_TOAST_NOTIFICATION, toast);

    setTimeout(() => {
      commit(REMOVE_TOAST_NOTIFICATION, { id: toast.id });
    }, parsedToastTimeout);
  },

  showWarningToast({ dispatch }, message) {
    dispatch('showToast', { message, status: WARNING });
  },

  async toggleChannel(_, id) {
    const service = new NotificationsService(useRuntimeConfig().public.notificationsBaseUrl);
    const data = await service.toggleChannel(id);

    return data;
  },

  async toggleRule(_, id) {
    const service = new NotificationsService(useRuntimeConfig().public.notificationsBaseUrl);
    const data = await service.toggleRule(id);

    return data;
  },

  async updateChannel(_, { id, payload }) {
    const service = new NotificationsService(useRuntimeConfig().public.notificationsBaseUrl);
    const data = await service.updateChannel(id, payload);

    return data;
  },

  async updateEmailPreferences(_, payload) {
    const service = new NotificationsService(useRuntimeConfig().public.notificationsBaseUrl);

    return await service.updateEmailPreferences(payload);
  },

  async updateRule(_, { id, payload }) {
    const service = new NotificationsService(useRuntimeConfig().public.notificationsBaseUrl);
    const data = await service.updateRule(id, payload);

    return data;
  },
};
