import { DatastoreService } from '~/support/services';

export default {
  async cancelProfiling(_, datastoreId) {
    const service = new DatastoreService(useRuntimeConfig().public.datastoresBaseUrl);
    const data = await service.cancelProfiling(datastoreId);

    return data;
  },

  async fetchDelimiters(_, datastoreId) {
    const service = new DatastoreService(useRuntimeConfig().public.datastoresBaseUrl);
    const data = await service.fetchDelimiters(datastoreId);

    return data;
  },

  async fetchOne(_, payload) {
    const service = new DatastoreService(useRuntimeConfig().public.datastoresBaseUrl);
    const data = await service.fetchOne(payload);

    return data;
  },

  async setNormalizer(_, { config, datastoreId, reprofile, type }) {
    const service = new DatastoreService(useRuntimeConfig().public.datastoresBaseUrl);
    const data = await service.setNormalizer(datastoreId, type, config, reprofile);

    return data;
  },

  async updateName(_, { datastoreId, name }) {
    const service = new DatastoreService(useRuntimeConfig().public.datastoresBaseUrl);
    const data = await service.update(datastoreId, { name });

    return data;
  },
};
