import { useAuth } from '~/support/composables/auth';

export default defineNuxtPlugin(() => {
  const { hasEntitlement } = useAuth();

  return {
    provide: {
      hasEntitlement,
    },
  };
});
