import { BE_ACTIVE_STATUS, SUBSCRIPTION_PLANS } from '~/support/constants';

export const TRIAL_SUBSCRIPTION = {
  endDate: '2023-04-04T00:00:00.000Z',
  plan: SUBSCRIPTION_PLANS.trial,
  startDate: '2023-03-21T00:00:00.000Z',
  status: BE_ACTIVE_STATUS,
};

export const CRUX_FORGE_ANNUAL_SUBSCRIPTION = {
  id: 'AzZiz7TYgiMXq2Kcf',
  itemPriceId: 'Pro-USD-Monthly',
  plan: SUBSCRIPTION_PLANS.cruxForgeAnnual,
  startDate: '2023-03-21T00:00:00.000Z',
  status: BE_ACTIVE_STATUS,
};

export const SUBSCRIPTION_COPY = {
  cruxForgeAnnual: {
    buttonText: 'Go Managed',
    finePrint: 'Customer can perform view-only operations',
    helpText: 'Crux Manages Data',
    title: 'Crux Managed Annual',
  },
  enterprise: {
    buttonText: 'Go Enterprise',
    helpText: 'Unlimited Active Data Products',
    title: 'Enterprise',
  },
  free: {
    buttonText: 'Go Free',
    finePrint: '² Supports creating data products but requires upgrade to activate',
    helpText: 'No Active Data Products',
    title: 'Free',
  },
  pro: {
    buttonText: 'Go Pro',
    helpText: 'Up to 25 Active Data Products',
    title: 'Pro',
  },
  trial: {
    buttonText: 'Go Pro Trial',
    finePrint: '¹ plan changes to FREE after trial',
    helpText: '1 Active Data Product',
    title: 'Pro Trial',
  },
};
