export const appHead = {"meta":[{"charset":"utf-8"},{"content":"width=device-width, initial-scale=1","name":"viewport"},{"content":"Crux","hid":"description","name":"description"},{"content":"telephone=no","name":"format-detection"}],"link":[{"href":"/favicon.ico","rel":"icon","type":"image/x-icon"},{"href":"https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&family=Lato:wght@700;800;900&family=Source+Sans+3:ital,wght@0,400;0,600;0,700;1,400&display=swap","rel":"stylesheet"}],"style":[],"script":[],"noscript":[],"htmlAttrs":{"lang":"en"},"title":"Welcome - Crux"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const appSpaLoaderTag = "div"

export const appSpaLoaderAttrs = {"id":"__nuxt-loader"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"

export const crawlLinks = false

export const spaLoadingTemplateOutside = false

export const purgeCachedData = true