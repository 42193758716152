<template>
  <div class="border px-1 py-2 relative rounded-lg" :class="disabled ? 'bg-gray-100 disabled' : 'bg-white enabled'">
    <div class="h-full w-full">
      <codemirror :value="modelValue" :options="computedOptions" @input="$emit('update:modelValue', $event)" />
      <button
        v-hubble="'copy-code'"
        class="absolute bg-gray-200 inline-flex items-center px-2 py-1.5 right-0 rounded-bl-lg space-x-2 text-gray-600 text-xs top-0"
        @click="handleCopyClick"
      >
        <cx-icon :name="copyIcon" size="lg" />
        <span>Copy code</span>
      </button>
    </div>
  </div>
</template>

<script>
import Codemirror from 'codemirror-editor-vue3';
import jsyaml from 'js-yaml';
import { defaultsDeep } from 'lodash-es';

import { CxIcon } from '../CxIcon';
import { COPIED_TIMEOUT } from '../constants';

import 'codemirror/addon/lint/json-lint';
import 'codemirror/addon/lint/lint';
import 'codemirror/addon/lint/yaml-lint';

import 'codemirror/mode/yaml/yaml.js';
import 'codemirror/mode/xml/xml.js';

window.jsyaml = jsyaml;

export const JSON = 'application/json';
export const XML = 'text/xml';
export const YAML = 'text/x-yaml';
export const LANGUAGES = ['', JSON, XML, YAML];

export const DEFAULT_OPTIONS = {
  gutters: ['CodeMirror-lint-markers'],
  lineNumbers: true,
  lint: { highlightLines: true },
  mode: '',
  readOnly: false,
  tabSize: 2,
};

export default {
  name: 'CxEditor',

  hubble: 'cx-editor',

  components: {
    CxIcon,
    Codemirror,
  },

  props: {
    disabled: {
      default: false,
      type: Boolean,
    },

    language: {
      default: '',
      type: String,
      validator: (value) => LANGUAGES.includes(value),
    },

    modelValue: {
      default: '',
      type: String,
    },

    /**
     * See available configuration options here:
     *
     * https://codemirror.net/5/doc/manual.html#config
     */
    options: {
      default: () => ({}),
      type: Object,
    },
  },

  data() {
    return {
      copied: false,
    };
  },

  computed: {
    computedOptions() {
      return defaultsDeep(
        {
          ...this.options,
          mode: this.language,
          readOnly: this.disabled,
        },
        DEFAULT_OPTIONS,
      );
    },

    copyIcon() {
      return this.copied ? 'check-line' : 'file-copy-line';
    },
  },

  methods: {
    handleCopyClick() {
      if (this.copied) return;

      this.copied = true;

      navigator.clipboard.writeText(this.modelValue);

      setTimeout(() => (this.copied = false), COPIED_TIMEOUT);
    },
  },
};
</script>

<style>
@import '~/node_modules/codemirror/addon/lint/lint.css';
@import '~/node_modules/codemirror/addon/hint/show-hint.css';

.CodeMirror pre.CodeMirror-line {
  @apply astro-text-code;
}

.CodeMirror-lint-tooltip {
  @apply bg-white shadow-md border-none p-4 rounded-lg font-mono text-gray-900;
}

.CodeMirror-lint-message {
  @apply pl-6;
}

.CodeMirror-lint-mark-warning,
.CodeMirror-lint-marker-warning,
.CodeMirror-lint-message-warning {
  background-image: url('~/assets/img/lint-warning-icon.svg');
}

.CodeMirror-lint-mark-error,
.CodeMirror-lint-marker-error,
.CodeMirror-lint-message-error {
  background-image: url('~/assets/img/lint-error-icon.svg');
}
</style>

<style scoped>
.enabled:deep(.CodeMirror-gutters) {
  @apply bg-white;
}

.disabled:deep(.CodeMirror-gutters) {
  @apply bg-gray-100;
}

*:deep(.CodeMirror) {
  @apply bg-transparent h-auto;
}

*:deep(.CodeMirror-lint-line-error) {
  @apply bg-red-100;
}

*:deep(.CodeMirror-lint-line-warning) {
  @apply bg-yellow-100;
}

*:deep(.CodeMirror-gutter-elt) {
  @apply flex items-center leading-6 font-mono;
}

*:deep(.CodeMirror-gutters) {
  @apply border-none;
}

/* *:deep(.codemirror-container) {
  @apply overflow-auto;
} */
</style>
