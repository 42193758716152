import {
  DISMISS_FREE_BANNER,
  DISMISS_SCHEDULE_BANNER,
  DISMISS_TRIAL_BANNER,
  DISMISS_DESTINATION_BANNER,
  DISMISS_PRO_ACTIVATION_LIMIT_BANNER,
  SET_CATALOG_PREVIEW_BANNER_DISPLAY,
  SET_SELECTED_TIMEZONE,
  SET_SIDEBAR_STATUS,
  SET_STUDIO_RIGHT_PANEL_STATUS,
} from './mutation-types';

export default {
  [DISMISS_DESTINATION_BANNER](state) {
    state.destinationBannerIsDismissed = true;
  },

  [DISMISS_FREE_BANNER](state) {
    state.freeBannerIsDismissed = true;
  },

  [DISMISS_PRO_ACTIVATION_LIMIT_BANNER](state) {
    state.proActivationLimitBannerIsDismissed = true;
  },

  [DISMISS_SCHEDULE_BANNER](state) {
    state.scheduleBannerIsDismissed = true;
  },

  [DISMISS_TRIAL_BANNER](state) {
    state.trialBannerIsDismissed = true;
  },

  [SET_CATALOG_PREVIEW_BANNER_DISPLAY](state, value) {
    state.showCatalogPreviewBanner = value;
  },

  [SET_SELECTED_TIMEZONE](state, timezone) {
    state.selectedTimezone = timezone;
  },

  [SET_SIDEBAR_STATUS](state, isOpened) {
    state.isSidebarOpen = isOpened;
  },

  [SET_STUDIO_RIGHT_PANEL_STATUS](state, isOpened) {
    state.showStudioRightPanel = isOpened;
  },
};
