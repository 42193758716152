import { find, get, groupBy, keyBy, orderBy } from 'lodash-es';

import {
  PROFILING_COMPLETED,
  PROFILING_ERROR,
  PROFILING_PENDING,
  PROFILING_SUBSCRIBED,
  PROFILING_SUBSCRIBING,
  PROFILING_SUCCESS,
  PROFILING_UNSUBSCRIBED,
  PROFILING_UNSUBSCRIBING,
} from '~/support/constants';

export default {
  connectionSelectOptions(state) {
    return orderBy(
      state.connections.map(({ id: value, name: label, method, type }) => {
        return {
          label,
          method,
          type,
          value,
        };
      }),
      [({ label }) => label.toLowerCase()],
    );
  },

  connectionsById(state) {
    return keyBy(state.connections, 'id');
  },

  datasets(state) {
    return get(state.dataProduct, 'datasets', []);
  },

  failedFrameIds(state, getters) {
    return getters.failedFrames.map((frame) => frame.id);
  },

  failedFrames(state, getters) {
    return getters.validFrames.filter((frame) => frame.status === PROFILING_ERROR);
  },

  flattenedFrameVersions(state, getters) {
    return getters.workflows.reduce((result, { frames }) => {
      frames.forEach(({ versions }) => {
        result = [...result, ...versions];
      });

      return result;
    }, []);
  },

  flattenedFrames(state) {
    if (!state.dataProduct?.datastoresMap) return [];

    return Object.values(state.dataProduct.datastoresMap).flat();
  },

  flattenedInferredFrameVersions(state, getters) {
    return getters.inferredDatasets.reduce((result, { frames }) => {
      frames.forEach(({ versions = [] }) => {
        result = [...result, ...versions];
      });

      return result;
    }, []);
  },

  flattenedInferredFrames(state, getters) {
    return getters.inferredDatasets.reduce((result, { frames }) => {
      result = [...result, ...frames];

      return result;
    }, []);
  },

  frameIds(state) {
    const inferredDatasets = get(state.dataProduct, 'inferredDatasets', []);

    return inferredDatasets.map((inferredDataset) => inferredDataset.frames.map((frame) => frame.id)).flat();
  },

  frameStatuses(state, getters) {
    return getters.flattenedFrames.map((frame) => frame.status);
  },

  framesCount(state, getters) {
    return getters.datasets.reduce((total, dataset) => total + dataset.numberOfFrames, 0);
  },

  groupedFrames(state) {
    if (!state.dataProduct || !state.dataProduct.workflows.length) return [];

    const flattenedFrames = state.dataProduct.workflows
      .map((workflow) =>
        workflow.frames.map((frame) => {
          return { ...frame, datasetCron: workflow.cron, datasetId: workflow.id };
        }),
      )
      .reduce((previous, current) => previous.concat(current));

    return groupBy(flattenedFrames, 'pattern');
  },

  inferredDatasets(state) {
    return get(state.dataProduct, 'inferredDatasets', []);
  },

  newlyDetectedFilenamePatterns(state, getters) {
    return getters.flattenedFrames.reduce((result, { steps }) => {
      return result.concat(get(steps, 'downloading.meta.discoveredPatterns', []));
    }, []);
  },

  profileCompleted(state, getters) {
    if (!getters.flattenedFrames?.length) return false;

    return getters.flattenedFrames.every(
      (frame) => frame.isStopped || [PROFILING_SUCCESS, PROFILING_ERROR].includes(frame.status),
    );
  },

  profileFailed(state, getters) {
    return (
      getters.profileResolved &&
      Boolean(getters.validFrames.length) &&
      getters.validFrames.some((frame) => frame.status === PROFILING_ERROR)
    );
  },

  profilePending(state, getters) {
    return getters.frameStatuses.includes(PROFILING_PENDING);
  },

  profileResolved(state, getters) {
    return (
      Boolean(getters.flattenedFrames.length) &&
      getters.flattenedFrames.every(
        (frame) => frame.isExcluded || frame.isStopped || [PROFILING_SUCCESS, PROFILING_ERROR].includes(frame.status),
      )
    );
  },

  profileSucceeded(state, getters) {
    return (
      getters.profileResolved &&
      Boolean(getters.validFrames.length) &&
      getters.validFrames.every((frame) => frame.status === PROFILING_SUCCESS)
    );
  },

  profiledDatasets(state, getters) {
    return get(state.dataProduct, 'profiledDatasets', []);
  },

  profiledFrame: (state, getters) => (id) => {
    return find(getters.flattenedFrames, { datastoreId: parseInt(id) });
  },

  profilingNotificationStatus(state) {
    if (state.profilingNotificationLoading) {
      if (state.profilingNotificationActive) return PROFILING_UNSUBSCRIBING;

      return PROFILING_SUBSCRIBING;
    }

    if (state.profilingNotificationActive) return PROFILING_SUBSCRIBED;

    return PROFILING_UNSUBSCRIBED;
  },

  profilingStatus(state, getters) {
    switch (true) {
      case getters.profileCompleted:
        return PROFILING_COMPLETED;
      case getters.profilePending:
        return PROFILING_PENDING;
      default:
    }
  },

  selectedDataset: (state, getters) => (id) => {
    return getters.inferredDatasets.filter((dataset) => dataset.id === id)[0];
  },

  selectedDatasetFailedFrameIds: (state, getters) => (id) => {
    const frames = getters.selectedDatasetFailedFrames(id);

    return frames.map((frame) => frame.id);
  },

  selectedDatasetFailedFrames: (state, getters) => (id) => {
    const frames = getters.selectedDatasetValidFrames(id);

    return frames.filter((frame) => frame.status === PROFILING_ERROR);
  },

  selectedDatasetFrameIds: (state, getters) => (id) => {
    const dataset = getters.selectedDataset(id);

    return dataset.frames.map((frame) => frame.id);
  },

  selectedDatasetFrames: (state, getters) => (id) => {
    const dataset = getters.selectedDataset(id);
    const datastoreIds = dataset.frames.map((frame) => frame.datastoreId);

    return getters.flattenedFrames.filter((frame) => datastoreIds.includes(frame.datastoreId));
  },

  selectedDatasetFramesStatuses: (state, getters) => (id) => {
    const frames = getters.selectedDatasetFrames(id);

    return frames.map((frame) => frame.status);
  },

  selectedDatasetProfileFailed: (state, getters) => (id) => {
    const frames = getters.selectedDatasetValidFrames(id);

    return (
      getters.selectedDatasetProfileResolved(id) &&
      Boolean(frames.length) &&
      frames.some((frame) => frame.status === PROFILING_ERROR)
    );
  },

  selectedDatasetProfilePending: (state, getters) => (id) => {
    const statuses = getters.selectedDatasetFramesStatuses(id);

    return statuses.includes(PROFILING_PENDING);
  },

  selectedDatasetProfileResolved: (state, getters) => (id) => {
    const frames = getters.selectedDatasetFrames(id);

    return (
      Boolean(frames) &&
      frames.every(
        (frame) => frame.isExcluded || frame.isStopped || [PROFILING_SUCCESS, PROFILING_ERROR].includes(frame.status),
      )
    );
  },

  selectedDatasetValidFrameIds: (state, getters) => (id) => {
    const validFrames = getters.selectedDatasetValidFrames(id);

    return validFrames.map((frame) => frame.id);
  },

  selectedDatasetValidFrames: (state, getters) => (id) => {
    const frames = getters.selectedDatasetFrames(id);

    return frames.filter((frame) => !frame.isExcluded && !frame.isStopped);
  },

  selectedFrame: (state, getters) => (id) => {
    return find(getters.flattenedFrames, { id }) || getters.profiledFrame(id);
  },

  selectedFrameProfileFailed: (state, getters) => (id) => {
    const frame = getters.selectedFrame(id);

    return frame.status === PROFILING_ERROR;
  },

  selectedFrameProfilePending: (state, getters) => (id) => {
    const frame = getters.selectedFrame(id);

    return frame.status === PROFILING_PENDING;
  },

  selectedFrameProfileResolved: (state, getters) => (id) => {
    const frame = getters.selectedFrame(id);

    return frame.isExcluded || frame.isStopped || [PROFILING_SUCCESS, PROFILING_ERROR].includes(frame.status);
  },

  selectedPatterns(state, getters) {
    if (!getters.inferredDatasets) return [];

    return getters.inferredDatasets.reduce((result, dataset) => {
      return result.concat(dataset.selectedPatterns);
    }, []);
  },

  validFrameIds(state, getters) {
    return getters.validFrames.map((frame) => frame.id);
  },

  validFrames(state, getters) {
    return getters.flattenedFrames.filter((frame) => !frame.isExcluded && !frame.isStopped);
  },

  workflows(state) {
    return get(state.dataProduct, 'workflows', []);
  },
};
