import { DESC } from './misc';
import { ACTIVATING, ACTIVATION_FAILED, DEACTIVATED, EDIT_UPDATING, TESTING, TEST_FAILED, TEST_PASSED } from './status';
import { SUBSCRIPTION_TYPES } from './subscriptions';

export const COMPLETED_STEP_STATUS = 'COMPLETED';
export const CREATED_AT_DEFAULT = 'N/A';
export const DATA_PRODUCT_AVAILABILITY_TYPES = {
  now: 'AVAILABLE_NOW',
  request: 'AVAILABLE_UPON_REQUEST',
};
export const DATA_PRODUCT_DETAILS_ROUTES = {
  detailsNext: 'data-products-id-details-next',
  detailsNextDataset: 'data-products-id-details-next-datasets-datasetId',
  detailsNextFrame: 'data-products-id-details-next-datasets-datasetId-tables-frameId',
};
export const DATA_PRODUCT_SETTINGS_ROUTES = {
  metadata: 'data-products-id-manage',
  owner: 'orgs-id',
  settings: 'data-products-id-manage-settings',
  subscribers: 'data-products-id-manage-subscribers',
  subscription: 'data-products-id-manage-subscription',
};

export const DATA_PRODUCT_FILTER_KEYS = {
  description: 'description',
};
export const DATA_PRODUCT_STATUS_TYPE = 'productStatusType';
export const DATA_PRODUCT_SUBSCRIPTION_ACTIVE_STATUS = 'Active';
export const DATA_PRODUCT_SUBSCRIPTION_INACTIVE_STATUS = 'Inactive';
export const DATA_PRODUCTS_ROUTE_NAME = 'data-products';
export const DATA_PRODUCTS_ENTITLEMENT_ROUTE_NAME = 'data-products-index-entitlements';
export const DATA_PRODUCTS_SORT_SETTINGS = { createdAt: DESC };
export const DATA_PRODUCT_DETAILS_NEXT_ROUTE_NAME = 'data-products-id-details-next';
export const DATA_PRODUCT_DETAILS_NEXT_DATASET_ID_ROUTE_NAME = 'data-products-id-details-next-datasets-datasetId';
export const MY_DATA_ROUTES = {
  eventConsole: 'data-products-id-details-next-datasets-datasetId-event-console',
  overview: 'data-products-id-details-next-datasets-datasetId-overview',
};
export const DATA_PRODUCT_DETAILS_NEXT_FRAME_ROUTE_NAME =
  'data-products-id-details-next-datasets-datasetId-tables-frameId';
export const DATASET_HEALTH_DELAY = 1000;
export const EXTRACTION_STATS_DELAY = 1000;
export const EVENTS_DELAY = 1000;
export const FIND_DATA_PRODUCT = 'find-data-product';
export const IMPORT_DATA_PRODUCT = 'import-data-product';
export const MAX_DATA_PRODUCT_DESCRIPTION_LENGTH = 2000;
export const MAX_DATA_PRODUCT_TEXT_AREA_LENGTH = 500;
export const MAX_DATA_PRODUCT_FAMILY_NAME_LENGTH = 100;
export const MAX_DATA_PRODUCT_NAME_LENGTH = 100;
export const MIN_DATA_PRODUCT_FAMILY_NAME_LENGTH = 4;
export const MIN_DATA_PRODUCT_NAME_LENGTH = 4;
export const MIN_SOURCE_QUERY_LENGTH = 3;
export const PROFILING_COMPLETED = 'completed';
export const PROFILING_ERROR = 'error';
export const PROFILING_PENDING = 'pending';
export const PROFILING_QUEUED = 'queued';
export const PROFILING_SUBSCRIBED = 'subscribed';
export const PROFILING_SUBSCRIBING = 'subscribing';
export const PROFILING_SUCCESS = 'success';
export const PROFILING_UNSUBSCRIBED = 'unsubscribed';
export const PROFILING_UNSUBSCRIBING = 'unsubscribing';
export const SCRATCH_CONNECTION_ROUTE_NAME = 'data-products-id-scratch-connection';
export const SCRATCH_DATA_MODELING_ROUTE_NAME = 'data-products-id-scratch-data-modeling';
export const SCRATCH_DESTINATIONS_ROUTE_NAME = 'data-products-id-scratch-destinations';
export const SCRATCH_FINALIZE_ROUTE_NAME = 'data-products-id-scratch-finalize';
export const SCRATCH_NOTIFICATION_RULES_ROUTE_NAME = 'data-products-id-scratch-notification-rules';
export const SCRATCH_PROFILE_ROUTE_NAME = 'data-products-id-scratch-profile';
export const SCRATCH_SCHEDULE_ROUTE_NAME = 'data-products-id-scratch-schedule';
export const DATA_PRODUCT_FROM_SCRATCH_STEPS = [
  {
    label: 'Connect',
    to: {
      name: SCRATCH_CONNECTION_ROUTE_NAME,
    },
  },
  {
    label: 'Datasets',
    to: {
      name: SCRATCH_PROFILE_ROUTE_NAME,
    },
  },
  {
    label: 'Modeling',
    to: {
      name: SCRATCH_DATA_MODELING_ROUTE_NAME,
    },
  },
  {
    label: 'Schedule',
    to: {
      name: SCRATCH_SCHEDULE_ROUTE_NAME,
    },
  },
  {
    label: 'Notifications',
    to: {
      name: SCRATCH_NOTIFICATION_RULES_ROUTE_NAME,
    },
  },
  {
    label: 'Destinations',
    to: {
      name: SCRATCH_DESTINATIONS_ROUTE_NAME,
    },
  },
  {
    label: 'Finalize',
    to: {
      name: SCRATCH_FINALIZE_ROUTE_NAME,
    },
  },
];
export const DAYS_OF_WEEK = [
  {
    fullName: 'Sunday',
    index: 0,
    shortName: 'S',
  },
  {
    fullName: 'Monday',
    index: 1,
    shortName: 'M',
  },
  {
    fullName: 'Tuesday',
    index: 2,
    shortName: 'T',
  },
  {
    fullName: 'Wednesday',
    index: 3,
    shortName: 'W',
  },
  {
    fullName: 'Thursday',
    index: 4,
    shortName: 'T',
  },
  {
    fullName: 'Friday',
    index: 5,
    shortName: 'F',
  },
  {
    fullName: 'Saturday',
    index: 6,
    shortName: 'S',
  },
];

export const DATA_PRODUCT_TYPE_OPTIONS = [
  {
    label: 'Data Product',
    value: 'Product',
  },
  {
    label: 'Package',
    value: 'Package',
  },
  {
    label: 'Validation',
    value: 'Validation',
  },
  {
    label: 'Wrangle',
    value: 'Wrangle',
  },
];

export const CANDIDATE = 'Candidate';
export const COMING_SOON = 'Coming Soon';
export const DONE = 'Done';
export const RETIRED = 'Retired';
export const METADATA = 'Metadata';
export const OWNER = 'Owner';
export const SUBSCRIBERS = 'Subscribers';
export const SUBSCRIPTION = 'Subscription';

export const DATA_PRODUCT_DETAILS_ICON_NAME_MAP = {
  metadata: 'list-settings-line',
  owner: 'account-circle-line',
  subscribers: 'user-star-line',
  subscription: 'todo-line',
};

export const DATA_PRODUCT_ACTIONS_ICON_MAP = {
  continueOnboarding: 'play-line',
  delete: 'delete-bin-7-line',
  viewDetails: 'eye-line',
};

export const DATA_PRODUCT_TOGGLE_TRUE_ICON = 'toggle-fill';
export const DATA_PRODUCT_TOGGLE_FALSE_ICON = 'toggle-line';

export const DATA_PRODUCT_STATUS_ICON_NAME_MAP = {
  Candidate: 'circle-line',
  'Coming Soon': 'calendar-event-line',
  Done: 'checkbox-circle-fill',
  Retired: 'indeterminate-circle-fill',
};

export const DATA_PRODUCT_STATUS_ICON_COLOR_MAP = {
  Candidate: 'text-gray-400',
  'Coming Soon': 'text-yellow-500',
  Done: 'text-green-500',
  Retired: 'text-red-600',
};

export const DATA_PRODUCT_STATUS_OPTIONS = ['Candidate', 'Coming Soon', 'Done', 'Rejected'];

export const DATA_PRODUCT_SUBSCRIPTION_STATUS_DISPLAY_MAP = {
  [DATA_PRODUCT_SUBSCRIPTION_ACTIVE_STATUS]: 'Active',
  [DATA_PRODUCT_SUBSCRIPTION_INACTIVE_STATUS]: 'Deactivated',
};

export const DATA_PRODUCT_SUBSCRIPTION_STATUS_ICON_COLOR_MAP = {
  [DATA_PRODUCT_SUBSCRIPTION_ACTIVE_STATUS]: 'text-green-500',
  [DATA_PRODUCT_SUBSCRIPTION_INACTIVE_STATUS]: 'text-gray-400',
};

export const DATA_PRODUCT_SUBSCRIPTION_STATUS_ICON_MAP = {
  [DATA_PRODUCT_SUBSCRIPTION_ACTIVE_STATUS]: 'checkbox-circle-fill',
  [DATA_PRODUCT_SUBSCRIPTION_INACTIVE_STATUS]: 'indeterminate-circle-fill',
};

export const DATA_PRODUCT_ACCESS_TYPE_OPTIONS = [
  {
    label: 'Commercial',
    value: 'VENDOR',
  },
  {
    label: 'Public',
    value: 'PUBLIC',
  },
];

export const DATA_PRODUCT_AVAILABILITY_TYPE_OPTIONS = [
  {
    label: 'Available now',
    value: DATA_PRODUCT_AVAILABILITY_TYPES.now,
  },
  {
    label: 'Available upon request',
    value: DATA_PRODUCT_AVAILABILITY_TYPES.request,
  },
];

export const ARCHIVE = 'archive';
export const ARCHIVE_DIR = 'archive_dir';
export const ARCHIVE_FILE = 'archive_file';
export const DIR = 'dir';
export const FILE = 'file';
export const DIRECTORY_TYPES = [ARCHIVE_DIR, DIR];
export const FILE_TYPES = [FILE, ARCHIVE_FILE];
export const DIRECTORY_HIERARCHY_DELIMITER = '/';
export const ROOT_PATH = '/';

export const EDIT_DATA_PRODUCT_ROUTE_NAMES = {
  datasets: 'data-products-id-edit-datasets',
  destinations: 'data-products-id-edit-destinations',
  schemas: 'data-products-id-edit-schemas',
};

/**
 * These values are a shot in the dark. We will need to
 * work with the API team
 * on real values.
 */
export const GROUPING_METHOD_OPTIONS = [
  {
    label: 'Source Archive and Detected Schedule',
    value: 'workflow_archive_schedule',
  },
  { label: 'Group Workflow by Schedule', value: 'workflow_schedule' },
  { label: 'Ungroup Workflows', value: 'ungroup_workflows' },
  { label: 'Create New Grouped Workflow', value: 'new_grouped_workflow' },
  {
    label: 'Exclude from Current Workflow',
    value: 'exclude_from_current_workflow',
  },
  {
    label: 'Move Into Existing Workflow',
    value: 'move_into_existing_workflow',
  },
  {
    label: 'Split and Clone to New CDU Blueprint',
    value: 'split_clone_new_cdu_blueprint',
  },
];

export const INVALID_DATA_PRODUCT_WIZARD_STATES = [
  ACTIVATING,
  ACTIVATION_FAILED,
  DEACTIVATED,
  TESTING,
  TEST_FAILED,
  TEST_PASSED,
];
export const MIN_CREATE_FROM_SCRATCH_STEP_TO_TRACK = 2;
export const UNKNOWN_TABLE_VALUE = '-';
export const DATA_PRODUCT_EDIT_STATES_DISABLED = [ACTIVATING, TESTING];
export const DATA_PRODUCT_EDIT_STATES_VISIBLE = [
  ...DATA_PRODUCT_EDIT_STATES_DISABLED,
  EDIT_UPDATING,
  TEST_FAILED,
  ACTIVATION_FAILED,
];

export const BE_ACTIVE_STATUS = 'ACTIVE';
export const BE_INACTIVE_STATUS = 'INACTIVE';

export const EMPTY_SUPPLIER_DOCK_LINK = { name: '', value: '' };
export const EMPTY_CONFIGURED_SCHEDULE = {
  daylightSavingTimeEnabled: false,
  schedule: '',
  timezone: 'UTC',
};
export const EMPTY_DECLARED_SCHEDULE = {
  schedule: '',
  timezone: 'UTC',
};

export const POST_SETUP_PHASES = {
  activation: 'activation',
  deactivation: 'deactivation',
  test: 'test',
};
export const DATA_PRODUCT_TYPE_MAP = {
  [SUBSCRIPTION_TYPES.edp]: 'Self-Service',
  [SUBSCRIPTION_TYPES.forge]: 'Managed',
  [SUBSCRIPTION_TYPES.supplier]: 'Supplier',
};
export const DATA_PRODUCT_TYPE_COLOR_MAP = {
  [SUBSCRIPTION_TYPES.edp]: 'darkBlue',
  [SUBSCRIPTION_TYPES.forge]: 'darkOrange',
  [SUBSCRIPTION_TYPES.supplier]: 'whiteOutline',
};
export const DATA_PRODUCT_TYPE_ICON_MAP = {
  [SUBSCRIPTION_TYPES.edp]: 'flashlight-line',
  [SUBSCRIPTION_TYPES.forge]: 'building-line',
  [SUBSCRIPTION_TYPES.supplier]: 'truck-line',
};
export const DATA_PRODUCT_IMPORT_FROM_CSV_ICON = 'upload-cloud-2-line';
export const DATA_PRODUCTS_ENTITLEMENT_DEFAULT_RESTRICTIONS_FORM = {
  type: '',
  validFrom: '',
  validTo: '',
};
export const DATA_PRODUCTS_ENTITLEMENT_RESTRICTIONS_TYPE_OPTIONS = [
  { label: 'History', value: 'History' },
  { label: 'New Subscription', value: 'New Subscription' },
  { label: 'Custom', value: 'Custom' },
];
export const DATA_PRODUCTS_ENTITLEMENT_SERVICE_TYPE_OPTIONS = [
  { label: 'Trial', value: 'Trial' },
  { label: 'Supplier Trial', value: 'Supplier Trial' },
  { label: 'Full Access', value: 'Full Access' },
];
export const DATA_PRODUCTS_ENTITLEMENT_STATUS_OPTIONS = [
  { label: 'Active', value: 'Active' },
  { label: 'Inactive', value: 'Inactive' },
];
export const [DATA_PRODUCTS_ENTITLEMENT_DEFAULT_STATUS_OPTION] = DATA_PRODUCTS_ENTITLEMENT_STATUS_OPTIONS;
export const DATA_PRODUCTS_ENTITLEMENT_SERVICE_FORM = {
  serviceEndDate: '',
  serviceStartDate: '',
  serviceType: '',
  status: DATA_PRODUCTS_ENTITLEMENT_DEFAULT_STATUS_OPTION.value,
};
