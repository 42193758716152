export const CRON_ANY_VALUE = '*';
export const CRON_DAILY = 'daily';
export const CRON_DAY_VALUES = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
export const CRON_END_TIME_SECONDS = 59;
export const CRON_END_TIME_MINUTES = 59;
export const CRON_END_TIME_HOURS = 23;
export const CRON_END_WEEKDAYS = 6;
export const CRON_PART_INDEX = {
  dayOfMonth: 3,
  daysOfWeek: 5,
  hours: 2,
  minutes: 1,
  months: 4,
  seconds: 0,
};
export const CRON_PART_INDEX_NEXT = {
  dayOfMonth: 2,
  daysOfWeek: 4,
  hours: 1,
  minutes: 0,
  months: 3,
};
export const CRON_PART_MAX_VALUE = {
  [CRON_PART_INDEX.daysOfWeek]: CRON_END_WEEKDAYS,
  [CRON_PART_INDEX.hours]: CRON_END_TIME_HOURS,
  [CRON_PART_INDEX.minutes]: CRON_END_TIME_MINUTES,
  [CRON_PART_INDEX.seconds]: CRON_END_TIME_SECONDS,
};
export const CRON_PART_MAX_VALUE_NEXT = {
  [CRON_PART_INDEX_NEXT.daysOfWeek]: CRON_END_WEEKDAYS,
  [CRON_PART_INDEX_NEXT.hours]: CRON_END_TIME_HOURS,
  [CRON_PART_INDEX_NEXT.minutes]: CRON_END_TIME_MINUTES,
};
export const CRON_BUILDER_DEFAULT = {
  dayOfWeek: [],
  endTime: null,
  minuteInterval: null,
  startTime: null,
};
export const CRON_BUILDER_CONFIGURED_DEFAULT = {
  dayOfWeek: [],
  endTime: 1,
  minuteInterval: 30,
  startTime: 0,
};
export const NON_INTRA_DAY_INTERVAL_OPTIONS = [
  {
    label: 'Once',
    value: 0,
  },
];
export const CRON_BUILDER_FORM_INTERVAL_VALUE = '*/30';
export const CRON_BUILDER_FORM_ONCE_VALUE = '0';
export const CRON_INTERVAL_OPTIONS = [
  {
    label: '30 min',
    value: CRON_BUILDER_FORM_INTERVAL_VALUE,
  },
  {
    label: 'Once',
    value: CRON_BUILDER_FORM_ONCE_VALUE,
  },
];
export const CRON_PARTS_COUNT = 6;
export const CRONS_PARTS_COUNT_NEXT = 5;
export const CRON_SECOND_DEFAULT = 0;
export const CRON_TIMEZONE = 'UTC';
export const CRON_END_TIME_OFFSET = 1;
export const HOURLY = 'Hourly';
export const ONCE = 'Once';
export const ONCE_A_DAY = 'Once a day';
export const ONCE_A_MONTH = 'Once a month';
export const ONCE_A_WEEK = 'Once a week';
export const ONCE_A_YEAR = 'Once a year';
export const SCHEDULE_ANNUALLY_EXPRESSION = '@annually';
export const SCHEDULE_DAILY_EXPRESSION = '@daily';
export const SCHEDULE_MIDNIGHT_EXPRESSION = '@midnight';
export const SCHEDULE_MONTHLY_EXPRESSION = '@monthly';
export const SCHEDULE_ONCE_EXPRESSION = '@once';
export const SCHEDULE_WEEKLY_EXPRESSION = '@weekly';
export const SCHEDULE_YEARLY_EXPRESSION = '@yearly';
export const SCHEDULE_HOURLY_EXPRESSION = '@hourly';
export const NON_INTRA_DAY_EXPRESSIONS = [
  SCHEDULE_ANNUALLY_EXPRESSION,
  SCHEDULE_DAILY_EXPRESSION,
  SCHEDULE_MIDNIGHT_EXPRESSION,
  SCHEDULE_MONTHLY_EXPRESSION,
  SCHEDULE_ONCE_EXPRESSION,
  SCHEDULE_WEEKLY_EXPRESSION,
  SCHEDULE_YEARLY_EXPRESSION,
];
export const CRON_SPECIAL_DESCRIPTIONS = {
  [SCHEDULE_ANNUALLY_EXPRESSION]: ONCE_A_YEAR,
  [SCHEDULE_DAILY_EXPRESSION]: ONCE_A_DAY,
  [SCHEDULE_HOURLY_EXPRESSION]: HOURLY,
  [SCHEDULE_MIDNIGHT_EXPRESSION]: ONCE_A_DAY,
  [SCHEDULE_MONTHLY_EXPRESSION]: ONCE_A_MONTH,
  [SCHEDULE_ONCE_EXPRESSION]: ONCE,
  [SCHEDULE_WEEKLY_EXPRESSION]: ONCE_A_WEEK,
  [SCHEDULE_YEARLY_EXPRESSION]: ONCE_A_YEAR,
};
export const CRON_SPECIAL_EXPRESSIONS = [
  SCHEDULE_ANNUALLY_EXPRESSION,
  SCHEDULE_DAILY_EXPRESSION,
  SCHEDULE_HOURLY_EXPRESSION,
  SCHEDULE_MIDNIGHT_EXPRESSION,
  SCHEDULE_MONTHLY_EXPRESSION,
  SCHEDULE_ONCE_EXPRESSION,
  SCHEDULE_WEEKLY_EXPRESSION,
  SCHEDULE_YEARLY_EXPRESSION,
];
export const CRON_HOUR_OPTIONS = [
  {
    label: '12:00am',
    value: 0,
  },
  {
    label: '1:00am',
    value: 1,
  },
  {
    label: '2:00am',
    value: 2,
  },
  {
    label: '3:00am',
    value: 3,
  },
  {
    label: '4:00am',
    value: 4,
  },
  {
    label: '5:00am',
    value: 5,
  },
  {
    label: '6:00am',
    value: 6,
  },
  {
    label: '7:00am',
    value: 7,
  },
  {
    label: '8:00am',
    value: 8,
  },
  {
    label: '9:00am',
    value: 9,
  },
  {
    label: '10:00am',
    value: 10,
  },
  {
    label: '11:00am',
    value: 11,
  },
  {
    label: '12:00pm',
    value: 12,
  },
  {
    label: '1:00pm',
    value: 13,
  },
  {
    label: '2:00pm',
    value: 14,
  },
  {
    label: '3:00pm',
    value: 15,
  },
  {
    label: '4:00pm',
    value: 16,
  },
  {
    label: '5:00pm',
    value: 17,
  },
  {
    label: '6:00pm',
    value: 18,
  },
  {
    label: '7:00pm',
    value: 19,
  },
  {
    label: '8:00pm',
    value: 20,
  },
  {
    label: '9:00pm',
    value: 21,
  },
  {
    label: '10:00pm',
    value: 22,
  },
  {
    label: '11:00pm',
    value: 23,
  },
];

export const HEATMAP_X_AXIS = ['12am', '3am', '6am', '9am', '12pm', '3pm', '6pm', '9pm'];
export const HEATMAP_Y_AXIS = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
