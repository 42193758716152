
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as plannZMPTUqjh76q0qcXk15P3G6DoVZkikC1WFShSQXPXegMeta } from "/app/pages/orgs/[id]/plan.vue?macro=true";
import { default as entitlementsBfm7wYfTI8dQ3tnJ_45JTSKhnQAJV7o1wVy3bNbN3m2tMMeta } from "/app/pages/orgs/[id]/index/entitlements.vue?macro=true";
import { default as indexxkm1oC3QTc1ZrO87PbyL_JpY_2hCGs_45jMCYGOhezo_45gMeta } from "/app/pages/orgs/[id]/index.vue?macro=true";
import { default as settings8vU7_45pOU1ahiJjOwJU_mEegJrdCbcRWmbzOyF21Vqf8Meta } from "/app/pages/orgs/[id]/data-products/[dataProductId]/settings.vue?macro=true";
import { default as subscribersuGXNUp_rq_45UekCSam_XikJmS2Aba9O1z_iT2qorJHGwMeta } from "/app/pages/orgs/[id]/data-products/[dataProductId]/subscribers.vue?macro=true";
import { default as subscriptionwp01S3xHBFXJLXUeWne4r3Yd92sv8OZ0dS_R0Mp9QtkMeta } from "/app/pages/orgs/[id]/data-products/[dataProductId]/subscription.vue?macro=true";
import { default as _91dataProductId_93K9n7sNXl1OJfxjMF0ooFPnB8u_454fN_45zS4WyBEmDoCO8Meta } from "/app/pages/orgs/[id]/data-products/[dataProductId].vue?macro=true";
import { default as data_45productsGVmaNzIw_458HCevaA062qjSvCBL89QfsBZlVMoU0cp1kMeta } from "/app/pages/orgs/[id]/data-products.vue?macro=true";
import { default as _91id_93zMzEE0qMSNuq8VrSxcKop0xpY8qyrgg82dkcYf_Fd70Meta } from "/app/pages/orgs/[id].vue?macro=true";
import { default as type8qmBlY1uCXmnRVbrPmPcra3QNFxFIAsOQFo8Hu7C2OoMeta } from "/app/pages/orgs/type.vue?macro=true";
import { default as index5WgjbMjki2h5fZY9_45xdckq6e7t_455cE_8Rzb3MjuQ684Meta } from "/app/pages/orgs/index.vue?macro=true";
import { default as indexyvmlveTf6LSfy8aQwLaG0PToQU14YoH7R2ReXdwNCEcMeta } from "/app/pages/orgs/create/index.vue?macro=true";
import { default as users9QSHDI6geyoZQsPX3aZnNJVBzfJ77HuH5Z18cEHytI4Meta } from "/app/pages/orgs/create/[id]/users.vue?macro=true";
import { default as nameKFrNs_45L9QOXWOxE_PbEVlhOndDMR4HUc_45YcNINJ5Oq0Meta } from "/app/pages/orgs/create/[type]/name.vue?macro=true";
import { default as plans6z94M2tDU80AfAX6BuQoNXHCwRHg3Di_45B8NISEkPeQYMeta } from "/app/pages/orgs/create/[type]/plans.vue?macro=true";
import { default as detailsdzKuXbwh_457uzud83Gu_wssK4PzCNd3vbXhkxufj7PXsMeta } from "/app/pages/orgs/create/[type]/details.vue?macro=true";
import { default as createOHhokkIBlHptfn5Wq352vYHupWRUejx8F9hFn9ZfXj4Meta } from "/app/pages/orgs/create.vue?macro=true";
import { default as orgsTh564x0pP2T_45UqHlAhU2uh6aSlnasG4ja_45gqubqdP38Meta } from "/app/pages/orgs.vue?macro=true";
import { default as indexndqPXFtP262szLmLJV4PriPTgAg5k_457f05QyTfosBXQMeta } from "/app/pages/index.vue?macro=true";
import { default as loginKGJhNRZhH4FU_45RnHfId5ZCGRhKzPnQPbdAbdNd47TwsMeta } from "/app/pages/login.vue?macro=true";
import { default as logoutUcooZuBFq6Y_45qkSHe3Slgw7Ozl4rsMv5kY96MDZ8icoMeta } from "/app/pages/logout.vue?macro=true";
import { default as create0GHU7MvGTib6o5vGqpp_kcsYy_PGPW2tdvgHNewzTikMeta } from "/app/pages/studio/data-products/create.vue?macro=true";
import { default as indexR1dmgVgBoCeIi0xSgPZpNVKgLlZJ6lsDPsuwDt3Lx5sMeta } from "/app/pages/studio/data-products/[dataProductId]/test/datasets/index.vue?macro=true";
import { default as overview_45G8K14Vhvx5wAsS7AObpb7tO7MC356hZzk8kwg3SH6AMeta } from "/app/pages/studio/data-products/[dataProductId]/test/datasets/[datasetId]/overview.vue?macro=true";
import { default as event_45consolexeBnsaDKyndiBRyEhBhI_a7SzF9KyBxPn_S_UQvTrsQMeta } from "/app/pages/studio/data-products/[dataProductId]/test/datasets/[datasetId]/event-console.vue?macro=true";
import { default as _91frameId_930qnHXC82UzNVqg6xJL1Y__yMaAvagOyErIz_dejocCkMeta } from "/app/pages/studio/data-products/[dataProductId]/test/datasets/[datasetId]/tables/[frameId].vue?macro=true";
import { default as _91datasetId_93yshSeVu7jR6JYLAIzlI9EwzsD_452V2DF7EDZ2NO4WiiIMeta } from "/app/pages/studio/data-products/[dataProductId]/test/datasets/[datasetId].vue?macro=true";
import { default as testRQnvGAeWLZ9VOP98oINN_45EpjkS8fxKuM8RLrtKNUnSUMeta } from "/app/pages/studio/data-products/[dataProductId]/test.vue?macro=true";
import { default as indexI3YbObag8wW1MdKbr_y66GcKeVEMDdgKKnBuZzeMRFIMeta } from "/app/pages/studio/data-products/[dataProductId]/index.vue?macro=true";
import { default as indexotGE1RR60qQ4uFVI8_1QutMnwYbLh77tZMxAAmqxLlAMeta } from "/app/pages/studio/data-products/[dataProductId]/model/index.vue?macro=true";
import { default as indexGRPDV0N8gaCzZTz_45puGC0n9faBREt0BoUJZnufBZl3AMeta } from "/app/pages/studio/data-products/[dataProductId]/model/datasets/[datasetId]/index.vue?macro=true";
import { default as index8qlwO2mAZMtMH3_45E1Ze0u0HqcvWGKmbvk_45Z9YqKm6DQMeta } from "/app/pages/studio/data-products/[dataProductId]/model/datasets/[datasetId]/tables/[frameId]/index.vue?macro=true";
import { default as model7Y_45wVdJcw1ga1K4q4w7_BHR_452OF64rWBMYZ41TH5Gh4Meta } from "/app/pages/studio/data-products/[dataProductId]/model.vue?macro=true";
import { default as overviewSCrHvFbMyFLpAouxlCUEjyNO1XGJ3OD4y4_qZlUaVw8Meta } from "/app/pages/studio/data-products/[dataProductId]/review/datasets/[datasetId]/overview.vue?macro=true";
import { default as event_45consolezfs9w5B1Ul4_459bCeIY8lBOsOS3QJZ0_45ZBH1H6qN7fr8Meta } from "/app/pages/studio/data-products/[dataProductId]/review/datasets/[datasetId]/event-console.vue?macro=true";
import { default as _91frameId_934sIJ_45iIvUj45J_S4X6v3pqoEKr_C5KWDMUAA2Tc5m7kMeta } from "/app/pages/studio/data-products/[dataProductId]/review/datasets/[datasetId]/tables/[frameId].vue?macro=true";
import { default as _91datasetId_934Gm_45cGsWe46xGL_u8zDVooI3yZIIFi1hdCGWhtvdSNUMeta } from "/app/pages/studio/data-products/[dataProductId]/review/datasets/[datasetId].vue?macro=true";
import { default as reviewzfeeg_45pmHV8Fqu1lVdM4X3HcWMOw5G1w5mSj7u4AMxoMeta } from "/app/pages/studio/data-products/[dataProductId]/review.vue?macro=true";
import { default as newM64VHR0GRe7Ya9HL5e6N65SDLs2ROGYKPbYmsLnGNqAMeta } from "/app/pages/studio/data-products/[dataProductId]/connect/new.vue?macro=true";
import { default as customJi2rKS_mczGILO01hJogLMpGLZWgXjDYYlvnrzcNZ3wMeta } from "/app/pages/studio/data-products/[dataProductId]/connect/custom.vue?macro=true";
import { default as existingQwMQLXpJcZLVc_Z6sgQmqcGNjwT_tjVLyDHQtvNA6CMMeta } from "/app/pages/studio/data-products/[dataProductId]/connect/existing.vue?macro=true";
import { default as connectpvA1_45FvTSML_452pFG4QxFw9_45SOqPzdw87z93jkg8OdoEMeta } from "/app/pages/studio/data-products/[dataProductId]/connect.vue?macro=true";
import { default as indexQtamVd4fOypJbIxpvqbTzZZs8w2j9_ObzznKWKNrV5MMeta } from "/app/pages/studio/data-products/[dataProductId]/schedule/datasets/index.vue?macro=true";
import { default as _91datasetId_93IqKatIafzxt7seJSadAaRTdRNW0jcOPVauM94UbGMjEMeta } from "/app/pages/studio/data-products/[dataProductId]/schedule/datasets/[datasetId].vue?macro=true";
import { default as scheduleH9ywEQiMrmd6e6BtCLTwlKGIE0Axh7jI9zKlV8h6weUMeta } from "/app/pages/studio/data-products/[dataProductId]/schedule.vue?macro=true";
import { default as select_45dataz86aMhRiu9Waf8q9r6Ly_456HsQH8nnIK97U05hsLp0BwMeta } from "/app/pages/studio/data-products/[dataProductId]/select-data.vue?macro=true";
import { default as destinations0Boc6CXKIT3bSgEIOGhkX49beVqa4NPD2TIVsgy0nR4Meta } from "/app/pages/studio/data-products/[dataProductId]/destinations.vue?macro=true";
import { default as _91dataProductId_93OyLtqZ0PVL3OTjC_45dnG0BPvfwalT0UFIMSK87HybQbUMeta } from "/app/pages/studio/data-products/[dataProductId].vue?macro=true";
import { default as data_45productsylg2dVVuiVVwMtcKEeF9ezk68BidQ_45kYe1dp0sjuK_45gMeta } from "/app/pages/studio/data-products.vue?macro=true";
import { default as studioBKU001IJ7I71zCmVdBl30bLWj3nE4uvJ7ye8CKluBDoMeta } from "/app/pages/studio.vue?macro=true";
import { default as indexXC6u2VncPUfY31iwP986P2UxnIv9rtdyTGlNpEWI3dUMeta } from "/app/pages/catalog/families/index.vue?macro=true";
import { default as indexOsLEyBz3dcSWHs0OJ37CyZmuYt87Q13_45cUWA1C9FuAoMeta } from "/app/pages/catalog/families/[familyName]/index.vue?macro=true";
import { default as _91frameId_93ERGam6IMTc7vrOSLpXZHnXrIsd0iflDSZe4RsU327VsMeta } from "/app/pages/catalog/families/[familyName]/[dataProductId]/datasets/[datasetId]/tables/[frameId].vue?macro=true";
import { default as _91datasetId_93WyLapma5YelHCaP9e_45T4BaX5_fxxH1EOsLXgmsowJG4Meta } from "/app/pages/catalog/families/[familyName]/[dataProductId]/datasets/[datasetId].vue?macro=true";
import { default as _91dataProductId_93btoeq2CInCTHzcUB8QeMy1g0hj3pLXUKSK_45UCLgDERgMeta } from "/app/pages/catalog/families/[familyName]/[dataProductId].vue?macro=true";
import { default as _91familyName_93LdTSCeLdTZ8kr0yIQNOH1EeuW8Oa3Ha8u5JNUZRL1eUMeta } from "/app/pages/catalog/families/[familyName].vue?macro=true";
import { default as familiesNlvxAZ_45_45ci7u13couJCd75XQJhyXvZPnTPnSXiwMRLUMeta } from "/app/pages/catalog/families.vue?macro=true";
import { default as index8PaNs9ONtpoD1z_m0pLiKHd61zm7QRUHFkNfv95JvRUMeta } from "/app/pages/catalog/suppliers/index.vue?macro=true";
import { default as indexsc4dufOhoaahy7b7WvRyFwqpprtinXscwitoEP10DhMMeta } from "/app/pages/catalog/suppliers/[supplierId]/index.vue?macro=true";
import { default as suppliers3wa68igfPP2SBacQuuxCShYnhdK70HPw_zb6DzVfqo8Meta } from "/app/pages/catalog/suppliers.vue?macro=true";
import { default as indexfUDzXDe0_45PxWEPQJxOK39BVPVSVfm8lNOH5KGs5kgbYMeta } from "/app/pages/catalog/data-products/index.vue?macro=true";
import { default as _91frameId_93qzB_9Pg0lvnTK66dh_InKRhJzSfg8WgDr80JNzWMG30Meta } from "/app/pages/catalog/data-products/[dataProductId]/datasets/[datasetId]/tables/[frameId].vue?macro=true";
import { default as _91datasetId_93o81vTweqcDzI7jjSjdHBHTjw72Xtd_45DfEUBDBYL68EMMeta } from "/app/pages/catalog/data-products/[dataProductId]/datasets/[datasetId].vue?macro=true";
import { default as _91dataProductId_93FOhLQfTTWQztpyG3EfozhG4ja1bIAhRRihevEtbXK_458Meta } from "/app/pages/catalog/data-products/[dataProductId].vue?macro=true";
import { default as data_45productsyQzk9B_45TB064TiUo0KjMA8oYlbNODOAIJPcd6bCHSEQMeta } from "/app/pages/catalog/data-products.vue?macro=true";
import { default as catalogLWWEIG0_45f7N2q2COWPs96jHzhShznO5jhI3mJ2jQoTsMeta } from "/app/pages/catalog.vue?macro=true";
import { default as indexDLzw_45g6D6DIAJf6VenrnDjf_u_45_OCTUbhH1U0CiVKeoMeta } from "/app/pages/settings/index.vue?macro=true";
import { default as indexfYrVptpWiaqtNfHBLmRkfF9QEAbBi90_Mx_8WcfXetIMeta } from "/app/pages/settings/usage/index.vue?macro=true";
import { default as history92EGOyKJql5gRPGRxVxsS283N5lFkzfLyi8Ezywhgz4Meta } from "/app/pages/settings/usage/history.vue?macro=true";
import { default as usageYIU6gw2ExSOtu2ARcuFFq8I9v1JDYmTYkz6OGm3KoD4Meta } from "/app/pages/settings/usage.vue?macro=true";
import { default as general6ntoyAD5efEFrmziwf8bsod_rd9ONOeTLC8Ihn5AnmkMeta } from "/app/pages/settings/general.vue?macro=true";
import { default as api_45keysRAJg_45hHvgFLNt_45YbbJ6_45K3el4Ohc91ZH8QLJeIfJpiIMeta } from "/app/pages/settings/api-keys.vue?macro=true";
import { default as indexjwgFgrTkNlKGDHaT6DdDvmRZMTH5nqaZRiYKosBkeGsMeta } from "/app/pages/settings/plan-details/index.vue?macro=true";
import { default as upgradeCAk3dGOGqyZPLsYQDb0IqbFESsF5tA3Ny_45Yg32UQYbsMeta } from "/app/pages/settings/plan-details/upgrade.vue?macro=true";
import { default as your_45plannQWddJyljWzPAtmag_TnUXPMoVtHkwYwfrqlW1qrbF4Meta } from "/app/pages/settings/plan-details/your-plan.vue?macro=true";
import { default as plan_45detailsX_45VEw3GdKAC3VHvAoUL5ELV45AZHN72HkCF3lQSm92IMeta } from "/app/pages/settings/plan-details.vue?macro=true";
import { default as index_45Q1mECiYEejd8aBg5M7oZjTpb1_wL5PQwbQgBQBeMyIMeta } from "/app/pages/settings/notifications/index.vue?macro=true";
import { default as sqsNtFsmWqkI4gd0VT2UyqVbyCwaBSCxFMfhY5OlL9NlRkMeta } from "/app/pages/settings/notifications/channels/sqs.vue?macro=true";
import { default as emailx3o4W_45NB603yxnCcZ71S6ZGeT7hTcoLxicQYPbKdhKwMeta } from "/app/pages/settings/notifications/channels/email.vue?macro=true";
import { default as indexjlTdSxOxpaTVt94mI_vkVTVfKii0nJv9Eq53qMOqVTQMeta } from "/app/pages/settings/notifications/channels/index.vue?macro=true";
import { default as pub_45subKnOkNiaXdQH1UIjjhQNFITEawxfKPu_8SPL0dyovNFAMeta } from "/app/pages/settings/notifications/channels/pub-sub.vue?macro=true";
import { default as webhook2iRuNwNn2JCnnX0tYzE_45KYCF2GswuIV_45Z7OZpqA205EMeta } from "/app/pages/settings/notifications/channels/webhook.vue?macro=true";
import { default as channelsSeRu6luQfQdKZjbxFa1yrg5QAsFt6MYA8OF0HqDRRb4Meta } from "/app/pages/settings/notifications/channels.vue?macro=true";
import { default as preferencesNmBtx1jZZaPj32x_45769UOeooLseZ68PKQuoKuuA7t2gMeta } from "/app/pages/settings/notifications/preferences.vue?macro=true";
import { default as index9_I4G6jenGQWzRwppSp6BWNVPOyeO2QGR2OMUBC68JUMeta } from "/app/pages/settings/notifications/rules/index.vue?macro=true";
import { default as createEOnx68AoxwmQKaQhAPu93ZSZPLRTXA5M_45T7hWxXlLpEMeta } from "/app/pages/settings/notifications/rules/create.vue?macro=true";
import { default as editp8kCiQSfKYi7b2UrEKwLfT5wOOYPLi9lh0_45jghSynqEMeta } from "/app/pages/settings/notifications/rules/[id]/edit.vue?macro=true";
import { default as indexSUX_45QMg0QSpaeLu2mYd_GsnU3JCsH93QvF7obBWkLJIMeta } from "/app/pages/settings/notifications/rules/[id]/index.vue?macro=true";
import { default as notificationsWUt0TcF6oSDlzx1RBvKu1fSjfw4nNGgmtf5XWcyGTR4Meta } from "/app/pages/settings/notifications.vue?macro=true";
import { default as add_45userFFyefbhy_45B__JBeqyoKKtU2RSD7E_zVyFlXIJazsU6AMeta } from "/app/pages/settings/user-management/add-user.vue?macro=true";
import { default as user_45managementeAadLi_ovf30eVWjrOO8r6oH5sAkcSnLmryMzqfDPdMMeta } from "/app/pages/settings/user-management.vue?macro=true";
import { default as settingsDGUPgOPonJUdwz8k5B4RVSmTRVCcKBNUebfgnD29s5kMeta } from "/app/pages/settings.vue?macro=true";
import { default as agreementtCsK_RrN4UnIZu432kiXgIbKytAVg6BP3PjuPdElAlUMeta } from "/app/pages/agreement.vue?macro=true";
import { default as index_45F_uAQC8N4LTDgd1cq42C2ftJy11F_99xjd_45gOWaGcoMeta } from "/app/pages/dashboard/index.vue?macro=true";
import { default as ExplorecMJJgFWCAN0uLCxoydZFehSdgza52GoLa4Gqh5K3D0AMeta } from "/app/pages/dashboard/subcomponents/Explore.vue?macro=true";
import { default as ShortcutsJvGL54M4VcYb8a2Er3Jo4_45uCPmdChc57VqY_r_0vXZAMeta } from "/app/pages/dashboard/subcomponents/Shortcuts.vue?macro=true";
import { default as ExploreCardMT5TrLOhFIhgQAo80EsjtUQHsycyvUcMhsg2PrBzeWMMeta } from "/app/pages/dashboard/subcomponents/ExploreCard.vue?macro=true";
import { default as ShortcutCardJOEyi6x_45tPcGtiOwQ8Re_45CMkH25d_459dz_45RfAfMGnm2UMeta } from "/app/pages/dashboard/subcomponents/ShortcutCard.vue?macro=true";
import { default as GettingStartedfBi_45Kym_cZCz2jbLBs8L_45kqhNGspbVJPnllVvrTcFk8Meta } from "/app/pages/dashboard/subcomponents/GettingStarted.vue?macro=true";
import { default as GettingStartedCardA3enzzA1_Fonxl3rZCpZgc8bsXltdvd0_45NBXt6gjZj4Meta } from "/app/pages/dashboard/subcomponents/GettingStartedCard.vue?macro=true";
import { default as dashboardMP4s_45_45Pnrs3lAogdEWxQSVgBglFzEw4XrIvkluYCTzEMeta } from "/app/pages/dashboard.vue?macro=true";
import { default as editNpPTdQIwXAZTMS0s_457O6nQlYogCs1vLr0Ir8ekutF8IMeta } from "/app/pages/data-products/[id]/edit.vue?macro=true";
import { default as indexSoAN9_UHoXnCTSscMxpSfAnSerBFgSKjsPpxqlFf0G0Meta } from "/app/pages/data-products/[id]/index.vue?macro=true";
import { default as index4OazkMAe5GzKpU0XIIV_g45cLGJyOZQ6jREGDj_MHGsMeta } from "/app/pages/data-products/[id]/manage/index.vue?macro=true";
import { default as settingsnk8GoY5ykczOcM3DARrNZv6kn6jbI4Hu9zccMv2BaTQMeta } from "/app/pages/data-products/[id]/manage/settings.vue?macro=true";
import { default as subscribersaWvInxHsr2y5kBVUjjFDIyAa2rw04aoM_SCBFGq_45v1YMeta } from "/app/pages/data-products/[id]/manage/subscribers.vue?macro=true";
import { default as subscriptionXEjru9gJ3Ya4Z8Vmj_45mE1C5jGf2BNxZvB5E8Q33zRCwMeta } from "/app/pages/data-products/[id]/manage/subscription.vue?macro=true";
import { default as manageDJ9r23XgxWVWQ51NOewgseec_IaKsy36q8DALXIkoCkMeta } from "/app/pages/data-products/[id]/manage.vue?macro=true";
import { default as profilexqKvMu8_459yoDhM9pA8KS9d8Ytz_bB6UM6Yn_45uhzTvPUMeta } from "/app/pages/data-products/[id]/scratch/profile.vue?macro=true";
import { default as finalize9Z4lo9ATfoHGNOfYwSpL35hSSpwplVJqLfLl5yWH1Y4Meta } from "/app/pages/data-products/[id]/scratch/finalize.vue?macro=true";
import { default as scheduleoQgy0KeKKecNpxvIcOOjKJwZByf9BLxtgs5XEp7joM0Meta } from "/app/pages/data-products/[id]/scratch/schedule.vue?macro=true";
import { default as connectionmBN_hOPnej1LoJ50KQf7A1JNtCbJxhSVE2nR3FKmj8AMeta } from "/app/pages/data-products/[id]/scratch/connection.vue?macro=true";
import { default as destinationsSxdnp53A6BkDYHZ6D0AmjN2Lp6EqjcgdlRSQQwPCw4oMeta } from "/app/pages/data-products/[id]/scratch/destinations.vue?macro=true";
import { default as index0TVTkv9pFnuXSx8QkF3642pJtkElqfunJhdpflytd40Meta } from "/app/pages/data-products/[id]/scratch/data-modeling/index.vue?macro=true";
import { default as indexT0LOecL0gx0xJa4ZiBYegvGUANuJNm1IYVO5bHgSvW0Meta } from "/app/pages/data-products/[id]/scratch/data-modeling/datasets/index.vue?macro=true";
import { default as indexuh_dXcdu2ToCUDQNP9dyvmfAckpauod9B4DSq4wtV1QMeta } from "/app/pages/data-products/[id]/scratch/data-modeling/datasets/[datasetId]/index.vue?macro=true";
import { default as indexp6fask1LmHoijmDbueZClfXBdoN_2K35tJEF9amMR5EMeta } from "/app/pages/data-products/[id]/scratch/data-modeling/datasets/[datasetId]/tables/index.vue?macro=true";
import { default as indexdCM1FQoG9i67BmimgLJ3bvp9xLyVk_45t3LQLES2Doti8Meta } from "/app/pages/data-products/[id]/scratch/data-modeling/datasets/[datasetId]/tables/[frameId]/index.vue?macro=true";
import { default as data_45modeling4zliQ3jmRkA4_erVvQuPcV5K_4pA9qn48ebpRfPhY_EMeta } from "/app/pages/data-products/[id]/scratch/data-modeling.vue?macro=true";
import { default as indexk4t5HxAOWbHWxtjn0Gztby4boOI_nZ5F_SyRjFNFd_IMeta } from "/app/pages/data-products/[id]/scratch/notification-rules/datasets/index.vue?macro=true";
import { default as indexqd6DWQYSofr9sFWISf9GqYBPBM14v5tVUy3t43oE_45oEMeta } from "/app/pages/data-products/[id]/scratch/notification-rules/datasets/[datasetId]/index.vue?macro=true";
import { default as notification_45rulesNksNl9OA67ATqSzeuuxmYa1ZpyVTP2ycZQxl_N9gdtkMeta } from "/app/pages/data-products/[id]/scratch/notification-rules.vue?macro=true";
import { default as scratchbPhji3_45x8s3gy9RvQJmZpwzJ32MYIgH22FGmUfUKpJwMeta } from "/app/pages/data-products/[id]/scratch.vue?macro=true";
import { default as overviewknHxbkHRYFte3v7atdCjxydwtARJzx_sON1MsNBkoT4Meta } from "/app/pages/data-products/[id]/details-next/datasets/[datasetId]/overview.vue?macro=true";
import { default as event_45consoleQneVWgpMesgqxj4NvlCtD8_453K83xo_45M7zmVUFJDbGIIMeta } from "/app/pages/data-products/[id]/details-next/datasets/[datasetId]/event-console.vue?macro=true";
import { default as _91frameId_93RTumaqPIrD3szdxdRBR4r6cAVGoBJ33Fpxw3kdxF6MsMeta } from "/app/pages/data-products/[id]/details-next/datasets/[datasetId]/tables/[frameId].vue?macro=true";
import { default as _91datasetId_93tpzB86FfPuQr_45M5iVvTRRoLk30ew7ju0aJbYxJtGzmEMeta } from "/app/pages/data-products/[id]/details-next/datasets/[datasetId].vue?macro=true";
import { default as details_45nextCywmmjlnYFeA6Rnm5PfMOSccHg2r9TeRsIfkoyH2rSwMeta } from "/app/pages/data-products/[id]/details-next.vue?macro=true";
import { default as _91id_93lxpumxCPwt6x_OnlW2Ppp1oXUHPbXVTqXFNlvKaE4pMMeta } from "/app/pages/data-products/[id].vue?macro=true";
import { default as entitlementsLJVbSza00A8tdZmB7ekrO8B0cJoTK7rrY6Hh_45a2DuCkMeta } from "/app/pages/data-products/index/entitlements.vue?macro=true";
import { default as index_Qd8BAsdpr2uiazjDnRevx_GF6c07FIFZEqP4EBTJqoMeta } from "/app/pages/data-products/index.vue?macro=true";
import { default as data_45products7Ctd3aJK4EuOegxR_UCFrn1K_45rq8_t42_KqqB4GY6JkMeta } from "/app/pages/data-products.vue?macro=true";
import { default as indexsgDGE9MKlR4cjvq7Zjb2D5Rx5xHcefwmYiwb1_45ZdlgAMeta } from "/app/pages/health-dashboard/index/datasets/[datasetId]/deliveries/[deliveryId]/index.vue?macro=true";
import { default as _91datasetId_93azI8x20EXgV7p_45Gg2MLjQCQeelBwSwND1E0XxMDzOqQMeta } from "/app/pages/health-dashboard/index/datasets/[datasetId].vue?macro=true";
import { default as indexgGoKoDr5t7smRwn54KMMq_1Tjjc674aqbenp1bq9NIYMeta } from "/app/pages/health-dashboard/index.vue?macro=true";
import { default as health_45dashboardz468_CTnxGLrEiTy2NcdvJveqIQRiTMwh5kCUqFZJ1cMeta } from "/app/pages/health-dashboard.vue?macro=true";
import { default as recover_45passwordA842Gl3haZZ5JJZ_zxaCenQKlhsgtJj1eAgZ1PbbfgoMeta } from "/app/pages/recover-password.vue?macro=true";
import { default as index8D8P4Otx9MqEPztQYJ4zcN4nODdsOJKDVODF_452F7RDsMeta } from "/app/pages/connection-settings/index.vue?macro=true";
import { default as connection_45settingstT6R3KGl5ymTy7oK7aE7Jci6j1DEpHW3WpLmZPvTYGYMeta } from "/app/pages/connection-settings.vue?macro=true";
import { default as terms_45and_45conditionsRU14D3TdeoevONy87DoGrbDHcLlYVbN3gK81tRUROX4Meta } from "/app/pages/terms-and-conditions.vue?macro=true";
import { default as indexABUUR7xrTfs4NGzLEd9kMAMwpo0A5sl8tiAx_4527Tef4Meta } from "/app/pages/password/[action]/[token]/[email]/index.vue?macro=true";
export default [
  {
    name: orgsTh564x0pP2T_45UqHlAhU2uh6aSlnasG4ja_45gqubqdP38Meta?.name,
    path: "/orgs",
    meta: orgsTh564x0pP2T_45UqHlAhU2uh6aSlnasG4ja_45gqubqdP38Meta || {},
    component: () => import("/app/pages/orgs.vue"),
    children: [
  {
    name: _91id_93zMzEE0qMSNuq8VrSxcKop0xpY8qyrgg82dkcYf_Fd70Meta?.name,
    path: ":id()",
    meta: _91id_93zMzEE0qMSNuq8VrSxcKop0xpY8qyrgg82dkcYf_Fd70Meta || {},
    component: () => import("/app/pages/orgs/[id].vue"),
    children: [
  {
    name: "orgs-id-plan",
    path: "plan",
    component: () => import("/app/pages/orgs/[id]/plan.vue")
  },
  {
    name: "orgs-id",
    path: "",
    component: () => import("/app/pages/orgs/[id]/index.vue"),
    children: [
  {
    name: "orgs-id-index-entitlements",
    path: "entitlements",
    component: () => import("/app/pages/orgs/[id]/index/entitlements.vue")
  }
]
  },
  {
    name: "orgs-id-data-products",
    path: "data-products",
    component: () => import("/app/pages/orgs/[id]/data-products.vue"),
    children: [
  {
    name: "orgs-id-data-products-dataProductId",
    path: ":dataProductId()",
    meta: _91dataProductId_93K9n7sNXl1OJfxjMF0ooFPnB8u_454fN_45zS4WyBEmDoCO8Meta || {},
    component: () => import("/app/pages/orgs/[id]/data-products/[dataProductId].vue"),
    children: [
  {
    name: "orgs-id-data-products-dataProductId-settings",
    path: "settings",
    component: () => import("/app/pages/orgs/[id]/data-products/[dataProductId]/settings.vue")
  },
  {
    name: "orgs-id-data-products-dataProductId-subscribers",
    path: "subscribers",
    component: () => import("/app/pages/orgs/[id]/data-products/[dataProductId]/subscribers.vue")
  },
  {
    name: "orgs-id-data-products-dataProductId-subscription",
    path: "subscription",
    component: () => import("/app/pages/orgs/[id]/data-products/[dataProductId]/subscription.vue")
  }
]
  }
]
  }
]
  },
  {
    name: "orgs-type",
    path: "type",
    component: () => import("/app/pages/orgs/type.vue")
  },
  {
    name: "orgs",
    path: "",
    component: () => import("/app/pages/orgs/index.vue")
  },
  {
    name: createOHhokkIBlHptfn5Wq352vYHupWRUejx8F9hFn9ZfXj4Meta?.name,
    path: "create",
    component: () => import("/app/pages/orgs/create.vue"),
    children: [
  {
    name: "orgs-create",
    path: "",
    component: () => import("/app/pages/orgs/create/index.vue")
  },
  {
    name: "orgs-create-id-users",
    path: ":id()/users",
    component: () => import("/app/pages/orgs/create/[id]/users.vue")
  },
  {
    name: "orgs-create-type-name",
    path: ":type()/name",
    component: () => import("/app/pages/orgs/create/[type]/name.vue")
  },
  {
    name: "orgs-create-type-plans",
    path: ":type()/plans",
    component: () => import("/app/pages/orgs/create/[type]/plans.vue")
  },
  {
    name: "orgs-create-type-details",
    path: ":type()/details",
    component: () => import("/app/pages/orgs/create/[type]/details.vue")
  }
]
  }
]
  },
  {
    name: "index",
    path: "/",
    meta: indexndqPXFtP262szLmLJV4PriPTgAg5k_457f05QyTfosBXQMeta || {},
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginKGJhNRZhH4FU_45RnHfId5ZCGRhKzPnQPbdAbdNd47TwsMeta || {},
    component: () => import("/app/pages/login.vue")
  },
  {
    name: "logout",
    path: "/logout",
    meta: logoutUcooZuBFq6Y_45qkSHe3Slgw7Ozl4rsMv5kY96MDZ8icoMeta || {},
    component: () => import("/app/pages/logout.vue")
  },
  {
    name: "studio",
    path: "/studio",
    meta: studioBKU001IJ7I71zCmVdBl30bLWj3nE4uvJ7ye8CKluBDoMeta || {},
    component: () => import("/app/pages/studio.vue"),
    children: [
  {
    name: "studio-data-products",
    path: "data-products",
    component: () => import("/app/pages/studio/data-products.vue"),
    children: [
  {
    name: "studio-data-products-create",
    path: "create",
    meta: create0GHU7MvGTib6o5vGqpp_kcsYy_PGPW2tdvgHNewzTikMeta || {},
    component: () => import("/app/pages/studio/data-products/create.vue")
  },
  {
    name: _91dataProductId_93OyLtqZ0PVL3OTjC_45dnG0BPvfwalT0UFIMSK87HybQbUMeta?.name,
    path: ":dataProductId()",
    component: () => import("/app/pages/studio/data-products/[dataProductId].vue"),
    children: [
  {
    name: "studio-data-products-dataProductId-test",
    path: "test",
    meta: testRQnvGAeWLZ9VOP98oINN_45EpjkS8fxKuM8RLrtKNUnSUMeta || {},
    component: () => import("/app/pages/studio/data-products/[dataProductId]/test.vue"),
    children: [
  {
    name: "studio-data-products-dataProductId-test-datasets",
    path: "datasets",
    component: () => import("/app/pages/studio/data-products/[dataProductId]/test/datasets/index.vue")
  },
  {
    name: "studio-data-products-dataProductId-test-datasets-datasetId",
    path: "datasets/:datasetId()",
    component: () => import("/app/pages/studio/data-products/[dataProductId]/test/datasets/[datasetId].vue"),
    children: [
  {
    name: "studio-data-products-dataProductId-test-datasets-datasetId-overview",
    path: "overview",
    component: () => import("/app/pages/studio/data-products/[dataProductId]/test/datasets/[datasetId]/overview.vue")
  },
  {
    name: "studio-data-products-dataProductId-test-datasets-datasetId-event-console",
    path: "event-console",
    component: () => import("/app/pages/studio/data-products/[dataProductId]/test/datasets/[datasetId]/event-console.vue")
  },
  {
    name: "studio-data-products-dataProductId-test-datasets-datasetId-tables-frameId",
    path: "tables/:frameId()",
    component: () => import("/app/pages/studio/data-products/[dataProductId]/test/datasets/[datasetId]/tables/[frameId].vue")
  }
]
  }
]
  },
  {
    name: "studio-data-products-dataProductId",
    path: "",
    component: () => import("/app/pages/studio/data-products/[dataProductId]/index.vue")
  },
  {
    name: model7Y_45wVdJcw1ga1K4q4w7_BHR_452OF64rWBMYZ41TH5Gh4Meta?.name,
    path: "model",
    component: () => import("/app/pages/studio/data-products/[dataProductId]/model.vue"),
    children: [
  {
    name: "studio-data-products-dataProductId-model",
    path: "",
    component: () => import("/app/pages/studio/data-products/[dataProductId]/model/index.vue")
  },
  {
    name: "studio-data-products-dataProductId-model-datasets-datasetId",
    path: "datasets/:datasetId()",
    component: () => import("/app/pages/studio/data-products/[dataProductId]/model/datasets/[datasetId]/index.vue")
  },
  {
    name: "studio-data-products-dataProductId-model-datasets-datasetId-tables-frameId",
    path: "datasets/:datasetId()/tables/:frameId()",
    component: () => import("/app/pages/studio/data-products/[dataProductId]/model/datasets/[datasetId]/tables/[frameId]/index.vue")
  }
]
  },
  {
    name: "studio-data-products-dataProductId-review",
    path: "review",
    component: () => import("/app/pages/studio/data-products/[dataProductId]/review.vue"),
    children: [
  {
    name: "studio-data-products-dataProductId-review-datasets-datasetId",
    path: "datasets/:datasetId()",
    component: () => import("/app/pages/studio/data-products/[dataProductId]/review/datasets/[datasetId].vue"),
    children: [
  {
    name: "studio-data-products-dataProductId-review-datasets-datasetId-overview",
    path: "overview",
    component: () => import("/app/pages/studio/data-products/[dataProductId]/review/datasets/[datasetId]/overview.vue")
  },
  {
    name: "studio-data-products-dataProductId-review-datasets-datasetId-event-console",
    path: "event-console",
    component: () => import("/app/pages/studio/data-products/[dataProductId]/review/datasets/[datasetId]/event-console.vue")
  },
  {
    name: "studio-data-products-dataProductId-review-datasets-datasetId-tables-frameId",
    path: "tables/:frameId()",
    component: () => import("/app/pages/studio/data-products/[dataProductId]/review/datasets/[datasetId]/tables/[frameId].vue")
  }
]
  }
]
  },
  {
    name: "studio-data-products-dataProductId-connect",
    path: "connect",
    component: () => import("/app/pages/studio/data-products/[dataProductId]/connect.vue"),
    children: [
  {
    name: "studio-data-products-dataProductId-connect-new",
    path: "new",
    component: () => import("/app/pages/studio/data-products/[dataProductId]/connect/new.vue")
  },
  {
    name: "studio-data-products-dataProductId-connect-custom",
    path: "custom",
    component: () => import("/app/pages/studio/data-products/[dataProductId]/connect/custom.vue")
  },
  {
    name: "studio-data-products-dataProductId-connect-existing",
    path: "existing",
    component: () => import("/app/pages/studio/data-products/[dataProductId]/connect/existing.vue")
  }
]
  },
  {
    name: "studio-data-products-dataProductId-schedule",
    path: "schedule",
    component: () => import("/app/pages/studio/data-products/[dataProductId]/schedule.vue"),
    children: [
  {
    name: "studio-data-products-dataProductId-schedule-datasets",
    path: "datasets",
    component: () => import("/app/pages/studio/data-products/[dataProductId]/schedule/datasets/index.vue")
  },
  {
    name: "studio-data-products-dataProductId-schedule-datasets-datasetId",
    path: "datasets/:datasetId()",
    component: () => import("/app/pages/studio/data-products/[dataProductId]/schedule/datasets/[datasetId].vue")
  }
]
  },
  {
    name: "studio-data-products-dataProductId-select-data",
    path: "select-data",
    component: () => import("/app/pages/studio/data-products/[dataProductId]/select-data.vue")
  },
  {
    name: "studio-data-products-dataProductId-destinations",
    path: "destinations",
    component: () => import("/app/pages/studio/data-products/[dataProductId]/destinations.vue")
  }
]
  }
]
  }
]
  },
  {
    name: "catalog",
    path: "/catalog",
    meta: catalogLWWEIG0_45f7N2q2COWPs96jHzhShznO5jhI3mJ2jQoTsMeta || {},
    component: () => import("/app/pages/catalog.vue"),
    children: [
  {
    name: familiesNlvxAZ_45_45ci7u13couJCd75XQJhyXvZPnTPnSXiwMRLUMeta?.name,
    path: "families",
    component: () => import("/app/pages/catalog/families.vue"),
    children: [
  {
    name: "catalog-families",
    path: "",
    component: () => import("/app/pages/catalog/families/index.vue")
  },
  {
    name: _91familyName_93LdTSCeLdTZ8kr0yIQNOH1EeuW8Oa3Ha8u5JNUZRL1eUMeta?.name,
    path: ":familyName()",
    component: () => import("/app/pages/catalog/families/[familyName].vue"),
    children: [
  {
    name: "catalog-families-familyName",
    path: "",
    component: () => import("/app/pages/catalog/families/[familyName]/index.vue")
  },
  {
    name: "catalog-families-familyName-dataProductId",
    path: ":dataProductId()",
    component: () => import("/app/pages/catalog/families/[familyName]/[dataProductId].vue"),
    children: [
  {
    name: "catalog-families-familyName-dataProductId-datasets-datasetId",
    path: "datasets/:datasetId()",
    component: () => import("/app/pages/catalog/families/[familyName]/[dataProductId]/datasets/[datasetId].vue"),
    children: [
  {
    name: "catalog-families-familyName-dataProductId-datasets-datasetId-tables-frameId",
    path: "tables/:frameId()",
    component: () => import("/app/pages/catalog/families/[familyName]/[dataProductId]/datasets/[datasetId]/tables/[frameId].vue")
  }
]
  }
]
  }
]
  }
]
  },
  {
    name: suppliers3wa68igfPP2SBacQuuxCShYnhdK70HPw_zb6DzVfqo8Meta?.name,
    path: "suppliers",
    component: () => import("/app/pages/catalog/suppliers.vue"),
    children: [
  {
    name: "catalog-suppliers",
    path: "",
    component: () => import("/app/pages/catalog/suppliers/index.vue")
  },
  {
    name: "catalog-suppliers-supplierId",
    path: ":supplierId()",
    component: () => import("/app/pages/catalog/suppliers/[supplierId]/index.vue")
  }
]
  },
  {
    name: data_45productsyQzk9B_45TB064TiUo0KjMA8oYlbNODOAIJPcd6bCHSEQMeta?.name,
    path: "data-products",
    component: () => import("/app/pages/catalog/data-products.vue"),
    children: [
  {
    name: "catalog-data-products",
    path: "",
    component: () => import("/app/pages/catalog/data-products/index.vue")
  },
  {
    name: "catalog-data-products-dataProductId",
    path: ":dataProductId()",
    component: () => import("/app/pages/catalog/data-products/[dataProductId].vue"),
    children: [
  {
    name: "catalog-data-products-dataProductId-datasets-datasetId",
    path: "datasets/:datasetId()",
    component: () => import("/app/pages/catalog/data-products/[dataProductId]/datasets/[datasetId].vue"),
    children: [
  {
    name: "catalog-data-products-dataProductId-datasets-datasetId-tables-frameId",
    path: "tables/:frameId()",
    component: () => import("/app/pages/catalog/data-products/[dataProductId]/datasets/[datasetId]/tables/[frameId].vue")
  }
]
  }
]
  }
]
  }
]
  },
  {
    name: settingsDGUPgOPonJUdwz8k5B4RVSmTRVCcKBNUebfgnD29s5kMeta?.name,
    path: "/settings",
    meta: settingsDGUPgOPonJUdwz8k5B4RVSmTRVCcKBNUebfgnD29s5kMeta || {},
    component: () => import("/app/pages/settings.vue"),
    children: [
  {
    name: "settings",
    path: "",
    meta: indexDLzw_45g6D6DIAJf6VenrnDjf_u_45_OCTUbhH1U0CiVKeoMeta || {},
    component: () => import("/app/pages/settings/index.vue")
  },
  {
    name: usageYIU6gw2ExSOtu2ARcuFFq8I9v1JDYmTYkz6OGm3KoD4Meta?.name,
    path: "usage",
    meta: usageYIU6gw2ExSOtu2ARcuFFq8I9v1JDYmTYkz6OGm3KoD4Meta || {},
    component: () => import("/app/pages/settings/usage.vue"),
    children: [
  {
    name: "settings-usage",
    path: "",
    meta: indexfYrVptpWiaqtNfHBLmRkfF9QEAbBi90_Mx_8WcfXetIMeta || {},
    component: () => import("/app/pages/settings/usage/index.vue")
  },
  {
    name: "settings-usage-history",
    path: "history",
    component: () => import("/app/pages/settings/usage/history.vue")
  }
]
  },
  {
    name: "settings-general",
    path: "general",
    component: () => import("/app/pages/settings/general.vue")
  },
  {
    name: "settings-api-keys",
    path: "api-keys",
    component: () => import("/app/pages/settings/api-keys.vue")
  },
  {
    name: plan_45detailsX_45VEw3GdKAC3VHvAoUL5ELV45AZHN72HkCF3lQSm92IMeta?.name,
    path: "plan-details",
    meta: plan_45detailsX_45VEw3GdKAC3VHvAoUL5ELV45AZHN72HkCF3lQSm92IMeta || {},
    component: () => import("/app/pages/settings/plan-details.vue"),
    children: [
  {
    name: "settings-plan-details",
    path: "",
    meta: indexjwgFgrTkNlKGDHaT6DdDvmRZMTH5nqaZRiYKosBkeGsMeta || {},
    component: () => import("/app/pages/settings/plan-details/index.vue")
  },
  {
    name: "settings-plan-details-upgrade",
    path: "upgrade",
    component: () => import("/app/pages/settings/plan-details/upgrade.vue")
  },
  {
    name: "settings-plan-details-your-plan",
    path: "your-plan",
    component: () => import("/app/pages/settings/plan-details/your-plan.vue")
  }
]
  },
  {
    name: notificationsWUt0TcF6oSDlzx1RBvKu1fSjfw4nNGgmtf5XWcyGTR4Meta?.name,
    path: "notifications",
    meta: notificationsWUt0TcF6oSDlzx1RBvKu1fSjfw4nNGgmtf5XWcyGTR4Meta || {},
    component: () => import("/app/pages/settings/notifications.vue"),
    children: [
  {
    name: "settings-notifications",
    path: "",
    meta: index_45Q1mECiYEejd8aBg5M7oZjTpb1_wL5PQwbQgBQBeMyIMeta || {},
    component: () => import("/app/pages/settings/notifications/index.vue")
  },
  {
    name: channelsSeRu6luQfQdKZjbxFa1yrg5QAsFt6MYA8OF0HqDRRb4Meta?.name,
    path: "channels",
    component: () => import("/app/pages/settings/notifications/channels.vue"),
    children: [
  {
    name: "settings-notifications-channels-sqs",
    path: "sqs",
    component: () => import("/app/pages/settings/notifications/channels/sqs.vue")
  },
  {
    name: "settings-notifications-channels-email",
    path: "email",
    component: () => import("/app/pages/settings/notifications/channels/email.vue")
  },
  {
    name: "settings-notifications-channels",
    path: "",
    meta: indexjlTdSxOxpaTVt94mI_vkVTVfKii0nJv9Eq53qMOqVTQMeta || {},
    component: () => import("/app/pages/settings/notifications/channels/index.vue")
  },
  {
    name: "settings-notifications-channels-pub-sub",
    path: "pub-sub",
    component: () => import("/app/pages/settings/notifications/channels/pub-sub.vue")
  },
  {
    name: "settings-notifications-channels-webhook",
    path: "webhook",
    component: () => import("/app/pages/settings/notifications/channels/webhook.vue")
  }
]
  },
  {
    name: "settings-notifications-preferences",
    path: "preferences",
    component: () => import("/app/pages/settings/notifications/preferences.vue")
  },
  {
    name: "settings-notifications-rules",
    path: "rules",
    component: () => import("/app/pages/settings/notifications/rules/index.vue")
  },
  {
    name: "settings-notifications-rules-create",
    path: "rules/create",
    component: () => import("/app/pages/settings/notifications/rules/create.vue")
  },
  {
    name: "settings-notifications-rules-id-edit",
    path: "rules/:id()/edit",
    component: () => import("/app/pages/settings/notifications/rules/[id]/edit.vue")
  },
  {
    name: "settings-notifications-rules-id",
    path: "rules/:id()",
    component: () => import("/app/pages/settings/notifications/rules/[id]/index.vue")
  }
]
  },
  {
    name: "settings-user-management",
    path: "user-management",
    meta: user_45managementeAadLi_ovf30eVWjrOO8r6oH5sAkcSnLmryMzqfDPdMMeta || {},
    component: () => import("/app/pages/settings/user-management.vue"),
    children: [
  {
    name: "settings-user-management-add-user",
    path: "add-user",
    component: () => import("/app/pages/settings/user-management/add-user.vue")
  }
]
  }
]
  },
  {
    name: "agreement",
    path: "/agreement",
    meta: agreementtCsK_RrN4UnIZu432kiXgIbKytAVg6BP3PjuPdElAlUMeta || {},
    component: () => import("/app/pages/agreement.vue")
  },
  {
    name: dashboardMP4s_45_45Pnrs3lAogdEWxQSVgBglFzEw4XrIvkluYCTzEMeta?.name,
    path: "/dashboard",
    meta: dashboardMP4s_45_45Pnrs3lAogdEWxQSVgBglFzEw4XrIvkluYCTzEMeta || {},
    component: () => import("/app/pages/dashboard.vue"),
    children: [
  {
    name: "dashboard",
    path: "",
    component: () => import("/app/pages/dashboard/index.vue")
  },
  {
    name: "dashboard-subcomponents-Explore",
    path: "subcomponents/Explore",
    component: () => import("/app/pages/dashboard/subcomponents/Explore.vue")
  },
  {
    name: "dashboard-subcomponents-Shortcuts",
    path: "subcomponents/Shortcuts",
    component: () => import("/app/pages/dashboard/subcomponents/Shortcuts.vue")
  },
  {
    name: "dashboard-subcomponents-ExploreCard",
    path: "subcomponents/ExploreCard",
    component: () => import("/app/pages/dashboard/subcomponents/ExploreCard.vue")
  },
  {
    name: "dashboard-subcomponents-ShortcutCard",
    path: "subcomponents/ShortcutCard",
    component: () => import("/app/pages/dashboard/subcomponents/ShortcutCard.vue")
  },
  {
    name: "dashboard-subcomponents-GettingStarted",
    path: "subcomponents/GettingStarted",
    component: () => import("/app/pages/dashboard/subcomponents/GettingStarted.vue")
  },
  {
    name: "dashboard-subcomponents-GettingStartedCard",
    path: "subcomponents/GettingStartedCard",
    component: () => import("/app/pages/dashboard/subcomponents/GettingStartedCard.vue")
  }
]
  },
  {
    name: data_45products7Ctd3aJK4EuOegxR_UCFrn1K_45rq8_t42_KqqB4GY6JkMeta?.name,
    path: "/data-products",
    meta: data_45products7Ctd3aJK4EuOegxR_UCFrn1K_45rq8_t42_KqqB4GY6JkMeta || {},
    component: () => import("/app/pages/data-products.vue"),
    children: [
  {
    name: _91id_93lxpumxCPwt6x_OnlW2Ppp1oXUHPbXVTqXFNlvKaE4pMMeta?.name,
    path: ":id()",
    meta: _91id_93lxpumxCPwt6x_OnlW2Ppp1oXUHPbXVTqXFNlvKaE4pMMeta || {},
    component: () => import("/app/pages/data-products/[id].vue"),
    children: [
  {
    name: "data-products-id-edit",
    path: "edit",
    component: () => import("/app/pages/data-products/[id]/edit.vue")
  },
  {
    name: "data-products-id",
    path: "",
    meta: indexSoAN9_UHoXnCTSscMxpSfAnSerBFgSKjsPpxqlFf0G0Meta || {},
    component: () => import("/app/pages/data-products/[id]/index.vue")
  },
  {
    name: manageDJ9r23XgxWVWQ51NOewgseec_IaKsy36q8DALXIkoCkMeta?.name,
    path: "manage",
    meta: manageDJ9r23XgxWVWQ51NOewgseec_IaKsy36q8DALXIkoCkMeta || {},
    component: () => import("/app/pages/data-products/[id]/manage.vue"),
    children: [
  {
    name: "data-products-id-manage",
    path: "",
    meta: index4OazkMAe5GzKpU0XIIV_g45cLGJyOZQ6jREGDj_MHGsMeta || {},
    component: () => import("/app/pages/data-products/[id]/manage/index.vue")
  },
  {
    name: "data-products-id-manage-settings",
    path: "settings",
    component: () => import("/app/pages/data-products/[id]/manage/settings.vue")
  },
  {
    name: "data-products-id-manage-subscribers",
    path: "subscribers",
    component: () => import("/app/pages/data-products/[id]/manage/subscribers.vue")
  },
  {
    name: "data-products-id-manage-subscription",
    path: "subscription",
    component: () => import("/app/pages/data-products/[id]/manage/subscription.vue")
  }
]
  },
  {
    name: "data-products-id-scratch",
    path: "scratch",
    meta: scratchbPhji3_45x8s3gy9RvQJmZpwzJ32MYIgH22FGmUfUKpJwMeta || {},
    component: () => import("/app/pages/data-products/[id]/scratch.vue"),
    children: [
  {
    name: "data-products-id-scratch-profile",
    path: "profile",
    component: () => import("/app/pages/data-products/[id]/scratch/profile.vue")
  },
  {
    name: "data-products-id-scratch-finalize",
    path: "finalize",
    component: () => import("/app/pages/data-products/[id]/scratch/finalize.vue")
  },
  {
    name: "data-products-id-scratch-schedule",
    path: "schedule",
    component: () => import("/app/pages/data-products/[id]/scratch/schedule.vue")
  },
  {
    name: "data-products-id-scratch-connection",
    path: "connection",
    component: () => import("/app/pages/data-products/[id]/scratch/connection.vue")
  },
  {
    name: "data-products-id-scratch-destinations",
    path: "destinations",
    component: () => import("/app/pages/data-products/[id]/scratch/destinations.vue")
  },
  {
    name: data_45modeling4zliQ3jmRkA4_erVvQuPcV5K_4pA9qn48ebpRfPhY_EMeta?.name,
    path: "data-modeling",
    component: () => import("/app/pages/data-products/[id]/scratch/data-modeling.vue"),
    children: [
  {
    name: "data-products-id-scratch-data-modeling",
    path: "",
    component: () => import("/app/pages/data-products/[id]/scratch/data-modeling/index.vue")
  },
  {
    name: "data-products-id-scratch-data-modeling-datasets",
    path: "datasets",
    component: () => import("/app/pages/data-products/[id]/scratch/data-modeling/datasets/index.vue")
  },
  {
    name: "data-products-id-scratch-data-modeling-datasets-datasetId",
    path: "datasets/:datasetId()",
    component: () => import("/app/pages/data-products/[id]/scratch/data-modeling/datasets/[datasetId]/index.vue")
  },
  {
    name: "data-products-id-scratch-data-modeling-datasets-datasetId-tables",
    path: "datasets/:datasetId()/tables",
    component: () => import("/app/pages/data-products/[id]/scratch/data-modeling/datasets/[datasetId]/tables/index.vue")
  },
  {
    name: "data-products-id-scratch-data-modeling-datasets-datasetId-tables-frameId",
    path: "datasets/:datasetId()/tables/:frameId()",
    component: () => import("/app/pages/data-products/[id]/scratch/data-modeling/datasets/[datasetId]/tables/[frameId]/index.vue")
  }
]
  },
  {
    name: "data-products-id-scratch-notification-rules",
    path: "notification-rules",
    component: () => import("/app/pages/data-products/[id]/scratch/notification-rules.vue"),
    children: [
  {
    name: "data-products-id-scratch-notification-rules-datasets",
    path: "datasets",
    component: () => import("/app/pages/data-products/[id]/scratch/notification-rules/datasets/index.vue")
  },
  {
    name: "data-products-id-scratch-notification-rules-datasets-datasetId",
    path: "datasets/:datasetId()",
    component: () => import("/app/pages/data-products/[id]/scratch/notification-rules/datasets/[datasetId]/index.vue")
  }
]
  }
]
  },
  {
    name: "data-products-id-details-next",
    path: "details-next",
    component: () => import("/app/pages/data-products/[id]/details-next.vue"),
    children: [
  {
    name: "data-products-id-details-next-datasets-datasetId",
    path: "datasets/:datasetId()",
    component: () => import("/app/pages/data-products/[id]/details-next/datasets/[datasetId].vue"),
    children: [
  {
    name: "data-products-id-details-next-datasets-datasetId-overview",
    path: "overview",
    component: () => import("/app/pages/data-products/[id]/details-next/datasets/[datasetId]/overview.vue")
  },
  {
    name: "data-products-id-details-next-datasets-datasetId-event-console",
    path: "event-console",
    component: () => import("/app/pages/data-products/[id]/details-next/datasets/[datasetId]/event-console.vue")
  },
  {
    name: "data-products-id-details-next-datasets-datasetId-tables-frameId",
    path: "tables/:frameId()",
    component: () => import("/app/pages/data-products/[id]/details-next/datasets/[datasetId]/tables/[frameId].vue")
  }
]
  }
]
  }
]
  },
  {
    name: "data-products",
    path: "",
    component: () => import("/app/pages/data-products/index.vue"),
    children: [
  {
    name: "data-products-index-entitlements",
    path: "entitlements",
    component: () => import("/app/pages/data-products/index/entitlements.vue")
  }
]
  }
]
  },
  {
    name: health_45dashboardz468_CTnxGLrEiTy2NcdvJveqIQRiTMwh5kCUqFZJ1cMeta?.name,
    path: "/health-dashboard",
    meta: health_45dashboardz468_CTnxGLrEiTy2NcdvJveqIQRiTMwh5kCUqFZJ1cMeta || {},
    component: () => import("/app/pages/health-dashboard.vue"),
    children: [
  {
    name: "health-dashboard",
    path: "",
    component: () => import("/app/pages/health-dashboard/index.vue"),
    children: [
  {
    name: "health-dashboard-index-datasets-datasetId",
    path: "datasets/:datasetId()",
    component: () => import("/app/pages/health-dashboard/index/datasets/[datasetId].vue"),
    children: [
  {
    name: "health-dashboard-index-datasets-datasetId-deliveries-deliveryId",
    path: "deliveries/:deliveryId()",
    component: () => import("/app/pages/health-dashboard/index/datasets/[datasetId]/deliveries/[deliveryId]/index.vue")
  }
]
  }
]
  }
]
  },
  {
    name: "recover-password",
    path: "/recover-password",
    meta: recover_45passwordA842Gl3haZZ5JJZ_zxaCenQKlhsgtJj1eAgZ1PbbfgoMeta || {},
    component: () => import("/app/pages/recover-password.vue")
  },
  {
    name: connection_45settingstT6R3KGl5ymTy7oK7aE7Jci6j1DEpHW3WpLmZPvTYGYMeta?.name,
    path: "/connection-settings",
    meta: connection_45settingstT6R3KGl5ymTy7oK7aE7Jci6j1DEpHW3WpLmZPvTYGYMeta || {},
    component: () => import("/app/pages/connection-settings.vue"),
    children: [
  {
    name: "connection-settings",
    path: "",
    component: () => import("/app/pages/connection-settings/index.vue")
  }
]
  },
  {
    name: "terms-and-conditions",
    path: "/terms-and-conditions",
    meta: terms_45and_45conditionsRU14D3TdeoevONy87DoGrbDHcLlYVbN3gK81tRUROX4Meta || {},
    component: () => import("/app/pages/terms-and-conditions.vue")
  },
  {
    name: "password-action-token-email",
    path: "/password/:action()/:token()/:email()",
    meta: indexABUUR7xrTfs4NGzLEd9kMAMwpo0A5sl8tiAx_4527Tef4Meta || {},
    component: () => import("/app/pages/password/[action]/[token]/[email]/index.vue")
  }
]