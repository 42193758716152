import {
  CRUX_ADMIN,
  DATA_ENGINEER,
  DATA_PRODUCT_EDITOR,
  DATA_PRODUCT_VIEWER,
  EDP,
  FORGE,
  ORGANIZATION_ADMIN,
  SUPPLIER,
} from '~/support/constants';

export const HOME_PAGE_MAP = {
  [EDP]: {
    healthDashboard: [DATA_PRODUCT_VIEWER],
    studio: [CRUX_ADMIN, DATA_ENGINEER, ORGANIZATION_ADMIN, DATA_PRODUCT_EDITOR],
  },
  [FORGE]: {
    dataProducts: [CRUX_ADMIN, DATA_ENGINEER],
    healthDashboard: [ORGANIZATION_ADMIN, DATA_PRODUCT_EDITOR, DATA_PRODUCT_VIEWER],
  },
  [SUPPLIER]: {
    dataProducts: [CRUX_ADMIN, DATA_ENGINEER],
    healthDashboard: [ORGANIZATION_ADMIN, DATA_PRODUCT_EDITOR, DATA_PRODUCT_VIEWER],
  },
};

export const NAV_ICONS = {
  catalog: 'book-2',
  connectionSettings: 'plug',
  dataProducts: 'server',
  healthDashboard: 'heart-pulse',
  organizations: 'building-4',
  studio: 'add-line',
};

export const NAV_ROUTES = Object.freeze({
  catalog: { name: 'catalog' }, // go to families until we have a design for the catalog landing page
  connectionSettings: { name: 'connection-settings' },
  dashboard: { name: 'dashboard' },
  dataProducts: { name: 'data-products' },
  discoverSuppliers: { name: 'discover-suppliers' },
  healthDashboard: { name: 'health-dashboard' },
  organizations: { name: 'orgs' },
  settings: { name: 'settings' },
  studio: { name: 'studio-data-products-create' },
});
