import approx from 'approximate-number';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { filesize } from 'filesize';

dayjs.extend(duration);
dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);
dayjs.extend(timezone);
dayjs.extend(utc);

export const FORMATS = {
  duration: 'HH:mm:ss',
  fullDate: 'MMM D, YYYY HH:mm A',
  isoDate: 'YYYY-MM-DD',
  isoDateTime: 'YYYY-MM-DD h:mma',
  isoDateTime24: 'YYYY-MM-DD HH:mm:ss',
  localizedDate: 'LL',
  localizedFull: 'LLL',
  localizedFullExtended: 'LLLL',
  localizedShortDate: 'll',
  localizedShortTime: 'lll',
  monthYearShort: 'MMM YYYY',
};

export default {
  approximateNumber(number) {
    return approx(number);
  },

  dateTime(timestamp, timezone = 'utc') {
    return dayjs.utc(timestamp).tz(timezone).format(FORMATS.isoDateTime);
  },

  daysBetween(start, end) {
    end = dayjs.utc(end);
    start = dayjs.utc(start);

    return end.diff(start, 'days');
  },

  duration(time, unit = 'milliseconds') {
    return dayjs.duration(time, unit).format(FORMATS.duration);
  },

  fileSize(bytes) {
    return filesize(bytes, { spacer: '' }).toLocaleLowerCase();
  },

  fromNow(time) {
    return dayjs.utc(time).fromNow();
  },

  fullDateTime(date) {
    return dayjs.utc(date).local().format(FORMATS.localizedFull);
  },

  fullDateTimeExtended(date) {
    return dayjs.utc(date).local().format(FORMATS.localizedFullExtended);
  },

  getTimezoneOffsetFromUTC(timezone, { hourFormat } = {}) {
    return hourFormat ? dayjs().tz(timezone).utcOffset() / 60 : dayjs().tz(timezone).utcOffset();
  },

  getUserTimezone() {
    return dayjs.tz.guess();
  },

  humanizeDuration(time) {
    return dayjs.duration(time).humanize();
  },

  isAfter(timestamp, deadlineTimestamp) {
    return dayjs(timestamp).isAfter(deadlineTimestamp);
  },

  localizedDate(date) {
    return dayjs.utc(date).local().format(FORMATS.localizedDate);
  },

  localizedShortDate(date) {
    return dayjs.utc(date).local().format(FORMATS.localizedShortDate);
  },

  localizedShortTimeDate(date, timezone) {
    return timezone
      ? dayjs.utc(date).tz(timezone).format(FORMATS.localizedShortTime)
      : dayjs.utc(date).local().format(FORMATS.localizedShortTime);
  },

  monthYearShort(date) {
    return dayjs.utc(date).local().format(FORMATS.monthYearShort);
  },

  numberWithCommas(number) {
    return parseFloat(number).toLocaleString('en');
  },

  percentage(partialValue, totalValue) {
    return Number(((100 * partialValue) / totalValue).toFixed(2));
  },

  shortTimeDate(date) {
    return dayjs.utc(date).format(FORMATS.localizedShortTime);
  },

  sqlTimestamp(timestamp) {
    return dayjs.utc(timestamp).format();
  },

  tableDate(date) {
    return dayjs.utc(date).local().format(FORMATS.isoDate);
  },

  tableDateTime(date) {
    return dayjs.utc(date).local().format(FORMATS.isoDateTime);
  },

  tableDateTime24(date) {
    return dayjs.utc(date).local().format(FORMATS.isoDateTime24);
  },

  timezoneTimestamp(timestamp, timezone) {
    return dayjs(timestamp).tz(timezone).format(FORMATS.fullDate);
  },
};
