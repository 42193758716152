export const OPERATORS = {
  contains: 'contains',
  in: 'in',
  is: 'is',
  isAfter: 'is_after',
  isBefore: 'is_before',
  isBetween: 'is_between',
  isOnOrAfter: 'is_on_or_after',
  isOnOrBefore: 'is_on_or_before',
};

export const DATE_OPERATOR_OPTIONS = [
  { label: 'is before', value: OPERATORS.isBefore },
  { label: 'is after', value: OPERATORS.isAfter },
  { label: 'is on or before', value: OPERATORS.isOnOrBefore },
  { label: 'is on or after', value: OPERATORS.isOnOrAfter },
];
