import { DESTINATION_LABELS } from '~/support/constants';

import ApiBase from './ApiBase';

export default class DestinationsService extends ApiBase {
  async createDestination(payload) {
    const { data } = await this.client.post('', payload);

    return data;
  }

  async deleteDestination(connectionId) {
    const { data } = await this.client.delete(`${connectionId}`);

    return data;
  }

  async editDestination(connectionId, connection) {
    const { data } = await this.client.put(`${connectionId}`, connection);

    return data;
  }

  async fetchAll(params) {
    const { data } = await this.client.get('', { params });

    return data;
  }

  async fetchOne(connectionId) {
    const { data } = await this.client.get(`${connectionId}`);

    return data;
  }

  async fetchProductionDestinations(queryParams) {
    const params = {
      ...queryParams,
      label: DESTINATION_LABELS.production,
    };
    const { data } = await this.client.get('', { params });

    return data;
  }

  async fetchTestDestinations(queryParams) {
    const params = {
      ...queryParams,
      label: DESTINATION_LABELS.test,
    };
    const { data } = await this.client.get('', { params });

    return data;
  }
}
