export const ACTIVATING = 'activating';
export const ACTIVATION_FAILED = 'activation failed';
export const ACTIVE = 'active';
export const COMPLETED = 'completed';
export const DEACTIVATED = 'deactivated';
export const DRAFT = 'draft';
export const EDIT_UPDATING = 'edit updating';
export const ERROR = 'error';
export const EXPIRED = 'expired';
export const FAILED = 'failed';
export const FAILURE = 'failure';
export const INVALID_DRAFT = 'invalid draft';
export const NOT_AVAILABLE = 'not available';
export const NOT_PROCESSED = 'notProcessed';
export const OBSOLETED = 'obsoleted';
export const PAUSED = 'paused';
export const PAUSING = 'pausing';
export const PENDING = 'pending';
export const PROFILING = 'profiling';
export const RUNNING = 'running';
export const SCHEDULED = 'scheduled';
export const SKIPPED = 'skipped';
export const STARTED = 'started';
export const STEP_FAILED_STATUS = 'FAILED';
export const SUCCESS = 'success';
export const WARNING = 'warning';
export const TEST_FAILED = 'test failed';
export const TEST_PASSED = 'test passed';
export const TESTING = 'testing';
export const PROFILE_DISPLAY_STATE_MAP = {
  datasets: [ACTIVATING, ACTIVATION_FAILED, EDIT_UPDATING, TESTING, TEST_FAILED, TEST_PASSED],
  results: [ACTIVATING, ACTIVATION_FAILED, EDIT_UPDATING, TEST_FAILED, TEST_PASSED],
  resultsV2: [ACTIVATING, ACTIVATION_FAILED, EDIT_UPDATING, TESTING, TEST_FAILED, TEST_PASSED],
  summary: [ACTIVATING, ACTIVATION_FAILED, EDIT_UPDATING, TESTING, TEST_FAILED, TEST_PASSED],
};

export const PROGRESS_STATUSES = {
  done: 'done',
  failed: 'failed',
  pending: 'pending',
  running: 'running',
  succeeded: 'succeeded',
};
