import { without } from 'lodash-es';

import {
  RESET_DATA,
  SET_CUSTOM_PATTERNS,
  SET_DELETED_FOUND_PATTERNS,
  SET_FOUND_PATTERNS,
  SET_MATCHING_FILES,
  SET_SELECTED_PATHS,
  SET_SELECTED_PATTERNS,
} from './mutation-types';

export default {
  addCustomPattern({ commit, state }, pattern) {
    commit(SET_CUSTOM_PATTERNS, [...state.customPatterns, pattern]);
  },

  deleteCustomPattern({ commit, state }, pattern) {
    commit(SET_CUSTOM_PATTERNS, without(state.customPatterns, pattern));

    const foundPatterns = state.foundPatterns.map((foundPattern) => foundPattern.filenamePattern);
    const newSelectedPatterns = state.selectedPatterns.filter(
      (selectedPattern) => selectedPattern !== pattern || foundPatterns.includes(selectedPattern),
    );

    if (newSelectedPatterns.length !== state.selectedPatterns.length) {
      commit(SET_SELECTED_PATTERNS, newSelectedPatterns);
    }
  },

  deleteFoundPattern({ commit, getters, state }, pattern) {
    commit(SET_DELETED_FOUND_PATTERNS, [...state.deletedFoundPatterns, pattern]);

    // update selected patterns
    const newSelectedPatterns = state.selectedPatterns.filter(
      (selectedPattern) => selectedPattern !== pattern.filenamePattern,
    );

    if (newSelectedPatterns.length !== state.selectedPatterns.length) {
      commit(SET_SELECTED_PATTERNS, newSelectedPatterns);
    }

    // update found patterns
    const newFoundPatterns = state.foundPatterns.filter(
      (foundPattern) => foundPattern.filenamePattern !== pattern.filenamePattern,
    );

    if (newFoundPatterns.length !== state.foundPatterns.length) {
      commit(SET_FOUND_PATTERNS, newFoundPatterns);
    }

    // update selectedPaths and newDeletedFoundPatterns if all patterns related to a specific folder are removed
    const newSelectedPaths = state.selectedPaths.filter((selectedPath) =>
      getters.foundPatternsBasePaths.includes(selectedPath),
    );

    if (newSelectedPaths.length !== state.selectedPaths.length) {
      const newDeletedFoundPatterns = state.deletedFoundPatterns.filter(
        (deletedFoundPattern) => !deletedFoundPattern.filenamePattern.startsWith(`${pattern.filenamePattern}`),
      );

      commit(SET_DELETED_FOUND_PATTERNS, newDeletedFoundPatterns);
      commit(SET_SELECTED_PATHS, newSelectedPaths);
    }
  },

  deselectAllPatterns({ commit }) {
    commit(SET_SELECTED_PATTERNS, []);
  },

  deselectPath({ commit, state }, path) {
    commit(SET_SELECTED_PATHS, without(state.selectedPaths, path));

    // update selected patterns
    const newSelectedPatterns = state.selectedPatterns.filter(
      (selectedPattern) => !selectedPattern.startsWith(`${path}/`) || state.customPatterns.includes(selectedPattern),
    );

    if (newSelectedPatterns.length !== state.selectedPatterns.length) {
      commit(SET_SELECTED_PATTERNS, newSelectedPatterns);
    }

    // update found patterns
    const newFoundPatterns = state.foundPatterns.filter(
      (foundPattern) =>
        !foundPattern.filenamePattern.startsWith(`${path}/`) ||
        state.customPatterns.includes(foundPattern.filenamePattern),
    );
    if (newFoundPatterns.length !== state.foundPatterns.length) {
      commit(SET_FOUND_PATTERNS, newFoundPatterns);
    }
  },

  deselectPattern({ commit, state }, pattern) {
    commit(SET_SELECTED_PATTERNS, without(state.selectedPatterns, pattern));
  },

  async fetchFoundPatterns({ commit, dispatch, state }, connectionId) {
    const originalFoundPatterns = await dispatch(
      'connections/discoverPatterns',
      { connectionId, paths: state.selectedPaths },
      { root: true },
    );
    const deletedFoundPatterns = state.deletedFoundPatterns.map(
      (deletedFoundPattern) => deletedFoundPattern.filenamePattern,
    );
    const foundPatterns = originalFoundPatterns.filter(
      (originalFoundPattern) => !deletedFoundPatterns.includes(originalFoundPattern.filenamePattern),
    );

    commit(SET_FOUND_PATTERNS, foundPatterns);

    return foundPatterns;
  },

  async fetchMatchingFiles({ commit, dispatch }, { connectionId, pattern }) {
    const matchingFiles = await dispatch(
      'connections/patternsSearchSource',
      {
        connectionId,
        patterns: [pattern],
      },
      { root: true },
    );

    commit(SET_MATCHING_FILES, { matchingFiles, pattern });

    return matchingFiles;
  },

  resetData({ commit }) {
    commit(RESET_DATA);
  },

  selectAllPatterns({ commit, getters }) {
    commit(SET_SELECTED_PATTERNS, getters.allPatterns);
  },

  selectPath({ commit, state }, path) {
    commit(SET_SELECTED_PATHS, [...state.selectedPaths, path]);
  },

  selectPattern({ commit, state }, pattern) {
    commit(SET_SELECTED_PATTERNS, [...state.selectedPatterns, pattern]);
  },

  togglePattern({ dispatch, state }, pattern) {
    if (state.selectedPatterns.includes(pattern)) {
      return dispatch('deselectPattern', pattern);
    }

    return dispatch('selectPattern', pattern);
  },
};
