<template>
  <svg
    v-hubble="'cx-spinner'"
    viewBox="0 0 51 51"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="animate-spin fill-current inline"
    :class="[sizeClasses, colorClass]"
  >
    <path
      d="M50.5 25.7046C50.5 39.5117 39.3071 50.7046 25.5 50.7046C11.6929 50.7046 0.5 39.5117 0.5 25.7046C0.5 11.8975 11.6929 0.70459 25.5 0.70459C39.3071 0.70459 50.5 11.8975 50.5 25.7046ZM5.04072 25.7046C5.04072 37.0039 14.2007 46.1639 25.5 46.1639C36.7993 46.1639 45.9593 37.0039 45.9593 25.7046C45.9593 14.4052 36.7993 5.24531 25.5 5.24531C14.2007 5.24531 5.04072 14.4052 5.04072 25.7046Z"
      :fill="baseColor"
    />
    <path
      d="M47.4838 19.9297C48.6965 19.6111 49.4312 18.365 49.004 17.1861C48.1466 14.8205 46.9355 12.5938 45.4084 10.5832C43.4226 7.96877 40.9413 5.77109 38.1062 4.11562C35.2711 2.46015 32.1377 1.37931 28.8849 0.934798C26.3833 0.59295 23.8488 0.632601 21.3673 1.04854C20.1306 1.25582 19.4065 2.50807 19.7251 3.72081C20.0436 4.93355 21.2847 5.64504 22.5252 5.46273C24.4256 5.18345 26.3596 5.17263 28.2701 5.43371C30.9321 5.79748 33.4964 6.68201 35.8166 8.0368C38.1367 9.39159 40.1673 11.1901 41.7924 13.3297C42.9588 14.8652 43.8998 16.5548 44.5906 18.3471C45.0415 19.5171 46.271 20.2482 47.4838 19.9297Z"
    />
  </svg>
</template>

<script>
import { BASE, LG, ICON, SM, XS, XL } from '../constants';

export const SIZE_CLASS_MAP = {
  [XS]: 'h-4 w-4',
  [ICON]: 'h-6 w-6',
  [SM]: 'h-8 w-8',
  [BASE]: 'h-12 w-12',
  [LG]: 'h-16 w-16',
  [XL]: 'h-24 w-24',
};

export const DANGER = 'danger';
export const PRIMARY = 'primary';
export const QUATERNARY = 'quaternary';
export const SECONDARY = 'secondary';
export const TERTIARY = 'tertiary';
export const WARN = 'warn';
export const WARN_OUTLINE = 'warn-outline';
export const WHITE = 'white';

export const COLOR_CLASS_MAP = {
  [DANGER]: 'text-red-600',
  [PRIMARY]: 'text-blue-600',
  [SECONDARY]: 'text-gray-900',
  [TERTIARY]: 'text-gray-900',
  [QUATERNARY]: 'text-gray-900',
  [WARN_OUTLINE]: 'text-gray-900',
  [WARN]: 'text-gray-900',
  [WHITE]: 'text-white',
};

export default {
  name: 'CxSpinner',

  props: {
    baseColor: {
      default: '#E5E7EB',
      type: String,
    },

    color: {
      type: String,
      default: PRIMARY,
      validator: (value) => Object.keys(COLOR_CLASS_MAP).includes(value),
    },

    size: {
      type: String,
      default: BASE,
      validator: (value) => Object.keys(SIZE_CLASS_MAP).includes(value),
    },
  },

  computed: {
    colorClass() {
      return COLOR_CLASS_MAP[this.color];
    },

    sizeClasses() {
      return SIZE_CLASS_MAP[this.size];
    },
  },
};
</script>
