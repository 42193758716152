import { OPERATORS } from './filtering';

export const DATASET_ROUTES = {
  documentation: 'data-products-id-details-datasets-datasetId-documentation',
  frames: 'data-products-id-details-datasets-datasetId-frames',
  history: 'data-products-id-details-datasets-datasetId-history',
  notificationRule: 'data-products-id-details-datasets-datasetId-notification-rule',
  results: 'data-products-id-details-datasets-datasetId-results',
  schedule: 'data-products-id-details-datasets-datasetId-schedule',
};
export const DELIVERY_STATUS_SUCCESS = 'SUCCESS';
export const DELIVERY_STATUS_ERROR = 'ERROR';
export const MAX_DATASET_NAME_LENGTH = 100;

export const DATASET_EVENT_STATUS_MAP = {
  apply: 'Apply',
  failure: 'Failure',
  success: 'Success',
  warning: 'Warning',
};

export const DATASET_EVENT_FILTERS = {
  timestamp: {
    operator: OPERATORS.isBetween,
    value: {
      end: '',
      start: '',
    },
  },
  type: {
    operator: OPERATORS.in,
    value: [],
  },
};

export const DATASET_EVENT_TYPE_ICON_MAP = {
  [DATASET_EVENT_STATUS_MAP.apply]: {
    color: 'text-blue-600',
    name: 'information-line',
  },
  [DATASET_EVENT_STATUS_MAP.failure]: {
    color: 'text-red-600',
    name: 'error-warning-line',
  },
  [DATASET_EVENT_STATUS_MAP.success]: {
    color: 'text-green-600',
    name: 'check-line',
  },
  [DATASET_EVENT_STATUS_MAP.warning]: {
    color: 'text-yellow-600',
    name: 'alert-line',
  },
};

export const DATASET_EVENT_TYPES = {
  failure: 'failure',
  information: 'information',
  success: 'success',
  warning: 'warning',
};

export const DATASET_STATUS_TYPE_MAP = {
  [DATASET_EVENT_STATUS_MAP.apply]: DATASET_EVENT_TYPES.information,
  [DATASET_EVENT_STATUS_MAP.failure]: DATASET_EVENT_TYPES.failure,
  [DATASET_EVENT_STATUS_MAP.success]: DATASET_EVENT_TYPES.success,
  [DATASET_EVENT_STATUS_MAP.warning]: DATASET_EVENT_TYPES.warning,
};

export const DATASET_ORIGINAL_STATE_MAP = {
  active: 'DEPLOY',
  inProgress: 'DEPLOYING',
};
export const DATASET_STATE_MAP = {
  active: 'ACTIVE',
  deleted: 'DELETED',
  draft: 'DRAFT',
  failed: 'DEPLOY_FAILED',
  inProgress: 'DEPLOY_IN_PROGRESS',
  paused: 'PAUSED',
};

export const DATASET_TEST_STATE_MAP = {
  active: 'ACTIVE_QE',
  deleted: 'DELETED',
  draft: 'DRAFT',
  failed: 'DEPLOY_QE_FAILED',
  inProgress: 'DEPLOY_QE_IN_PROGRESS',
  paused: 'PAUSED',
};

export const DATASET_STATE_LABELS = {
  [DATASET_STATE_MAP.active]: 'active',
  [DATASET_STATE_MAP.deleted]: 'deleted',
  [DATASET_STATE_MAP.draft]: 'draft',
  [DATASET_STATE_MAP.failed]: 'failed',
  [DATASET_STATE_MAP.inProgress]: 'inProgress',
  [DATASET_STATE_MAP.paused]: 'paused',
  [DATASET_ORIGINAL_STATE_MAP.active]: 'active',
  [DATASET_ORIGINAL_STATE_MAP.inProgress]: 'inProgress',
  [DATASET_TEST_STATE_MAP.active]: 'active',
  [DATASET_TEST_STATE_MAP.deleted]: 'deleted',
  [DATASET_TEST_STATE_MAP.draft]: 'draft',
  [DATASET_TEST_STATE_MAP.failed]: 'failed',
  [DATASET_TEST_STATE_MAP.inProgress]: 'inProgress',
  [DATASET_TEST_STATE_MAP.paused]: 'paused',
};

export const DATASET_STATE_ICON_MAP = {
  active: {
    color: 'text-green-600',
    name: 'check-line',
  },
  failed: {
    color: 'text-red-600',
    name: 'error-warning-line',
  },
};

export const DATASET_STATE_PILL_MAP = {
  active: {
    color: 'green',
    icon: 'checkbox-circle-fill',
  },
  deployFailed: {
    color: 'red',
    icon: 'error-warning-line',
  },
  draft: {
    color: 'gray',
    icon: 'useImage',
  },
};
