import { zipObject } from 'lodash-es';

export const MODEL_STATUS_MAP = {
  error: 'error',
  running: 'running',
  success: 'success',
};

const CREATING_SCHEMAS = 'creatingSchemas';

export const MODEL_STEPS = zipObject([CREATING_SCHEMAS], [CREATING_SCHEMAS]);

const CUSTOM = 'custom';
const DELIMITED = 'delimited';
const JSON = 'json';
const XML = 'xml';
const RAW = 'raw';

export const NORMALIZATION_TYPES = zipObject([CUSTOM, DELIMITED, JSON, XML, RAW], [CUSTOM, DELIMITED, JSON, XML, RAW]);

const DATA_PRODUCT = 'dataProduct';
const DATASET = 'dataset';
const FRAME = 'frame';

export const MODEL_CONTEXTS = zipObject([DATA_PRODUCT, DATASET, FRAME], [DATA_PRODUCT, DATASET, FRAME]);

const DELIVER_RAW = 'deliverRaw';
const PROFILE = 'profile';
const REPROFILE = 'reprofile';
const REPROFILE_RAW = 'reprofileRaw';

export const MODEL_FUNCTIONS = zipObject(
  [DELIVER_RAW, PROFILE, REPROFILE, REPROFILE_RAW],
  [DELIVER_RAW, PROFILE, REPROFILE, REPROFILE_RAW],
);
