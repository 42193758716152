export const ldStubs = {
  enableArchiveGuardrails: true,
  enableDataProductActions: false,
  enableDataProductApply: false,
  enableDeleteDataset: true,
  enableEditActiveDataProduct: true,
  enableNotificationRulesRecon: true,
  enablePartialProfiling: true,
  linkToCruxStudio: true,
  showConnectionFileTypes: false,
  showConnectionStoragePath: false,
  showCruxStudio: true,
  showCustomConnectors: true,
  showCustomFilenamePatterns: true,
  showDataProductAddSubscribers: true,
  showDataProductAddSubscription: true,
  showDataProductOverviewDestinations: false,
  showDatasetDeliveryDeadlines: true,
  showDatasetDocumentsView: true,
  showDatasetScheduleView: true,
  showDatasetTableLocations: true,
  showDeactivateOrg: true,
  showDelimitedNormalizerSettings: false,
  showDeployDatasets: true,
  showFrameProfilingNotification: false,
  showHealthDashboardV2: true,
  showImportFromCsvModal: true,
  showInferredScheduleCard: true,
  showInferredScheduleHeatmap: false,
  showInferredScheduleNotificationButton: false,
  showJsonNormalizerSettings: false,
  showMyDataDatasetStates: true,
  showNotificationPreferences: true,
  showNotificationSettings: true,
  showNotificationsCenter: false,
  showOrgActivityOverview: false,
  showOrgAutoDeletionBanner: true,
  showOrgCreateDataProductDropdown: true,
  showOrgCreateDataProductEntitlements: true,
  showOrgDataLicenses: true,
  showOrgExposureCard: false,
  showOrgsDetails: true,
  showPauseDataProduct: true,
  showProfiledDatasets: true,
  showProfilerSearch: false,
  showProfilingDeliveriesModal: false,
  showRearrangeDatasets: false,
  showRerunDatasetDelivery: true,
  showSchemaVersions: true,
  showSubscriptionPlans: true,
  showTestResults: false,
  showTestStep: true,
  showUsagePlanStats: false,
  showXmlNormalizerSettings: false,
  useDataProductsV4: true,
  useFivePartCron: true,
};
