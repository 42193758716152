import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8 from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import legacy_iCRfOD1lr4GlPMu1O76BbS_eznweCTgA_U02ePx26R4 from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/legacy.mjs";
import i18n_eR5V0lSnXWNjUEeXy6F2aR__1Fg_Hl_5P4I2ulw7yoY from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import index_RALEYvZUir4xK8CrZUpDXB_Yuu2ZUuujLbgd0CkmMxw from "/app/plugins/launch-darkly/index.js";
import _1_init_ZFqAuvPZWMpUDbQUBD49CZaKefGOe6UC5npq1raBJ10 from "/app/plugins/1.init.js";
import compatibility_vue2_NAiNBgFqpoCHq8LeocEGPtZ6bIhk18cNo1F2ln1w9EI from "/app/plugins/compatibility-vue2.js";
import cx_components_h8s9bfeom6WAtz08koKd8eXbbdDYK6lomvpO442ZHyQ from "/app/plugins/cx-components.js";
import entitlements_ObID7DBEaZIdxdpj_Drv_9IUXt6_n_i40sEgFxOkpvQ from "/app/plugins/entitlements.js";
import hubble_wmNiJbLW_b4__rj5DwTdi_4miqMfKXBjLBcuyS4gL3U from "/app/plugins/hubble.js";
import intercom_j_TOlVxdoTvtggfiL8NlBhyinwVNpYch3ok_HGNaAlU from "/app/plugins/intercom.js";
import mixpanel_n5yEZ6LO2WDuAOlTdAeiek5JezGHgZhYtjpr1EwQGfM from "/app/plugins/mixpanel.js";
import portal_vue_Hxsjcd_z1kyy_LdNp52R_qMajbazLphgSHXEbtpsODg from "/app/plugins/portal-vue.js";
import rollbar_iAQGp0GDoMAFIfxc37DPMo_2kKuX9DTLnBBdO04zAOM from "/app/plugins/rollbar.js";
import route_names_DTWrZqwqG8aYq90rj2TJnarRhWtNdLtnWPHh_TaVNwY from "/app/plugins/route-names.js";
import utils_HmCNFsoiUT_ay4iCxoVbs9EhUCCzd_4yTVAwhMdGfyc from "/app/plugins/utils.js";
import vee_validate__2bfAHR5jEWrgXdXOPIb_c4VonIeemKRcUwX_VkEWhA from "/app/plugins/vee-validate.js";
import vue_virtual_scroller_YzxXfKUAWJqW9xhFOgW4aHyN_qZhkD3ykVaOQ6LznZ0 from "/app/plugins/vue-virtual-scroller.js";
import vuex_Cb31k4Ks1qmWFXmcNgSBiNrvbaoWmDqoJPBvBovLj9Q from "/app/plugins/vuex.js";
import websockets_9qn63PmfmarxAYoNgYv3lltLPeRuEKC4uuwJurFfTq4 from "/app/plugins/websockets.js";
export default [
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  legacy_iCRfOD1lr4GlPMu1O76BbS_eznweCTgA_U02ePx26R4,
  i18n_eR5V0lSnXWNjUEeXy6F2aR__1Fg_Hl_5P4I2ulw7yoY,
  index_RALEYvZUir4xK8CrZUpDXB_Yuu2ZUuujLbgd0CkmMxw,
  _1_init_ZFqAuvPZWMpUDbQUBD49CZaKefGOe6UC5npq1raBJ10,
  compatibility_vue2_NAiNBgFqpoCHq8LeocEGPtZ6bIhk18cNo1F2ln1w9EI,
  cx_components_h8s9bfeom6WAtz08koKd8eXbbdDYK6lomvpO442ZHyQ,
  entitlements_ObID7DBEaZIdxdpj_Drv_9IUXt6_n_i40sEgFxOkpvQ,
  hubble_wmNiJbLW_b4__rj5DwTdi_4miqMfKXBjLBcuyS4gL3U,
  intercom_j_TOlVxdoTvtggfiL8NlBhyinwVNpYch3ok_HGNaAlU,
  mixpanel_n5yEZ6LO2WDuAOlTdAeiek5JezGHgZhYtjpr1EwQGfM,
  portal_vue_Hxsjcd_z1kyy_LdNp52R_qMajbazLphgSHXEbtpsODg,
  rollbar_iAQGp0GDoMAFIfxc37DPMo_2kKuX9DTLnBBdO04zAOM,
  route_names_DTWrZqwqG8aYq90rj2TJnarRhWtNdLtnWPHh_TaVNwY,
  utils_HmCNFsoiUT_ay4iCxoVbs9EhUCCzd_4yTVAwhMdGfyc,
  vee_validate__2bfAHR5jEWrgXdXOPIb_c4VonIeemKRcUwX_VkEWhA,
  vue_virtual_scroller_YzxXfKUAWJqW9xhFOgW4aHyN_qZhkD3ykVaOQ6LznZ0,
  vuex_Cb31k4Ks1qmWFXmcNgSBiNrvbaoWmDqoJPBvBovLj9Q,
  websockets_9qn63PmfmarxAYoNgYv3lltLPeRuEKC4uuwJurFfTq4
]